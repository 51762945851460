import React from "react";
import R14, { StyleSheet, ScreenLayout } from "../core";
import ManualEntryPipelineDataTable from "../components/ManualEntryPipelineDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";

export default R14.connect(
  class ProjectManualEntryPipelinesScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.project.type),
        "manualEntryPipeline"
      );
    }
    
    render() {
      let project = this.props.project;
      return (
        <ProjectScreenLayout
          project={project}
          headerTitle='Manual Entry'
          floatingActionButton={
            this.props.app.dm.user.checkPermissions(
              this.props.app.dm.project.getKeyByType(project.type),
              "manualEntryPipeline",
              "create"
            ) && {
              onPress: () =>
                this.props.app.nav.to("projectManualEntryPipelineCreate", {
                  uid: project.uid,
                  key: this.props.app.dm.project.getKeyByType(project.type),
                }),
              icon: "add",
            }
          }
          DataTableComponent={
            <ManualEntryPipelineDataTable project={this.props.project} />
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({});
