import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  ScrollView,
  View,
  Text,
  DialogFormField,
  StyleSheet,
} from "../core";
import LabelView from "../components/LabelView";
export default R14.connect(
  class UserEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRoleValueChange = this.handleRoleValueChange.bind(this);
      this.handleMfaTypeChange = this.handleMfaTypeChange.bind(this);
      this.handleDeploymentsChange = this.handleDeploymentsChange.bind(this);
      this.handleDeploymentsDialogOpen =
        this.handleDeploymentsDialogOpen.bind(this);
      this.deploymentItemRenderer = this.deploymentItemRenderer.bind(this);
      this.state = {
        showPermGroups: this.shouldShowPermGroupByRole(
          this.props.formData.values && this.props.formData.values.role
            ? this.props.formData.values.role
            : null
        ),
        mfaType:
          this.props.formData &&
          this.props.formData.values &&
          this.props.formData.values.mfaType
            ? this.props.formData.values.mfaType
            : null,
        showClientKey: false,
        r14DeploymentSelections: [],
        r14DeploymentUids: [],
      };

      this._r14DeploymentSelections =
        this.props.formData && this.props.formData.r14DeploymentSelections
          ? this.props.formData.r14DeploymentSelections
          : [];

      this._r14DeploymentInstance =
        this.props.formData && this.props.formData.r14DeploymentInstance
          ? this.props.formData.r14DeploymentInstance
          : null;

      this._useR14Deployments = this._r14DeploymentInstance.master === true;

      console.log("check dep", this.props.formData);

      // if (!this._r14DeploymentInstance)
      //   throw new Error("Deployment error. Please contact admin.S");
    }
    get r14DeploymentInstance() {
      return this._r14DeploymentInstance;
    }
    get useR14Deployments() {
      return this._useR14Deployments;
    }
    handleDeploymentsDialogOpen(item = null) {
      if (item)
        this.setState({
          showClientKey: false,
          r14DeploymentSelections: [],
        });
      else
        this.setState({
          showClientKey: true,
          r14DeploymentSelections: this._r14DeploymentSelections,
        });
    }
    handleMfaTypeChange(value) {
      switch (value) {
        case this.props.app.dm.user.MFA_TYPE_EMAIL:
          this.form.elmts.phone.setRequired(false);
          this.form.elmts.phone.setHelper("Optional");
          break;
        case this.props.app.dm.user.MFA_TYPE_SMS:
          this.form.elmts.phone.setRequired("Enter Phone");
          this.form.elmts.phone.setHelper(null);
          break;
        default:
          this.form.elmts.phone.setRequired(false);
          this.form.elmts.phone.setHelper("Optional");
      }
      this.setState({ mfaType: value });
    }
    handleRoleValueChange(value) {
      this.setState({
        showPermGroups: this.shouldShowPermGroupByRole(value),
      });
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("userForm");
    }
    shouldShowPermGroupByRole(role) {
      let ret = false;
      let userDomain = this.props.app.dm.user;
      switch (role) {
        case userDomain.ROLE_GROUP:
          ret = true;
          break;
      }
      return ret;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.user[editMethod](form.values);

        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "users";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "users"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    handleDeploymentsChange(values) {
      let r14DeploymentUids = values
        ? values.map((v) => v.r14DeploymentUid)
        : [];
      this.setState({ r14DeploymentUids });
    }
    getR14DeploymentSelections() {
      let deploymentForm = this.props.app.ui.form;
      console.log(deploymentForm);
      console.log("getR14DeploymentSelections");
      return this.props.formData.r14DeploymentSelections;
    }
    getDeploymentLabel(uuid) {
      let r14Deployments = this.props.formData.r14DeploymentSelections.filter(
        (d) => (d.value === uuid ? true : false)
      );
      return r14Deployments.length ? r14Deployments[0].label : "N/A";
    }

    deploymentItemRenderer(values) {
      return (
        <View>
          <View style={styles.itemRow}>
            <LabelView style={styles.itemLabelView} key='name' label='Name'>
              <Text>{this.getDeploymentLabel(values.r14DeploymentUuid)}</Text>
            </LabelView>
          </View>
        </View>
      );
    }
    render() {
      return (
        <Form
          name='userForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          {/* <SelectMenuField
            useValuesOnly
            name="clientUid"
            label="Client"
            required="Select Client"
            items={this.props.formData.clientSelections}
          /> */}
          <TextInputField name='name' label='Name' required='Enter Name' />
          {/* <TextInputField
            name="username"
            label="Username"
            required="Enter Username"
          /> */}
          <FieldGroup>
            <TextInputField
              name='email'
              label='Email'
              validator='email'
              required='Enter Email'
            />

            <TextInputField name='phone' label='Phone' helper='Optional' />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='password'
              label={this.isEditMode ? "Change Password" : "Password"}
              secure
              required={!this.isEditMode && "Enter Password"}
            />

            <SelectMenuField
              useValuesOnly
              name='mfaType'
              label='Multi-Factor Auth'
              items={this.formData.mfaTypeSelections}
              onValueChange={this.handleMfaTypeChange}
            />
          </FieldGroup>
          {this.useR14Deployments && (
            <DialogFormField
              name='r14DeploymentConfigs'
              dialogTitle='Add Deployment'
              label='Deployments'
              formName='deploymentForm'
              itemRenderer={this.deploymentItemRenderer}
              // onRemoveItem={this.handleRemoveBlockIoItem}
              // validator='uniqueKey'
              onValueChange={this.handleDeploymentsChange}
              // onItemEditPress={this.handleDeploymentItemEditPress}
              onDialogOpen={this.handleDeploymentsDialogOpen}
            >
              <SelectMenuField
                useValuesOnly
                name='r14DeploymentUuid'
                label='Deployment'
                required='Select Deployment'
                items={this.getR14DeploymentSelections()}
              />
              {this.state.showClientKey && (
                <TextInputField
                  name='clientKey'
                  label='Client Key'
                  required='Enter Client Key'
                />
              )}
              <SelectMenuField
                useValuesOnly
                name='role'
                label='Role'
                onValueChange={this.handleRoleValueChange}
                required='Select role'
                items={this.props.formData.roleSelections}
              />
              {this.state.showPermGroups && (
                <TextInputField
                  name='userPermissionGroupKey'
                  label='Permission Group Key'
                  required='Enter Permission Group'
                />
              )}

              <SwitchField name='active' label='Is Active' value={true} />
            </DialogFormField>
          )}
          {!this.useR14Deployments && (
            <React.Fragment>
              <SelectMenuField
                useValuesOnly
                name='role'
                label='Role'
                onValueChange={this.handleRoleValueChange}
                required='Select role'
                items={this.props.formData.roleSelections}
              />
              {this.state.showPermGroups &&
                this.props.formData.userPermissionGroupSelections.length >
                  0 && (
                  <SelectMenuField
                    useValuesOnly
                    name='userPermissionGroupUid'
                    label='Permission Group'
                    required='Select Permission Group'
                    items={this.props.formData.userPermissionGroupSelections}
                  />
                )}
              <SwitchField name='active' label='Is Active' value={true} />{" "}
            </React.Fragment>
          )}
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  itemRow: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: -16,
  },
  itemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 8,
  },
  itemLabelView: {
    width: "50%",
    minWidth: 0,
    flex: 1,
    marginBottom: 12,
  },
});
