import R14, { AsyncStorage, AsyncSessionStorage } from "../core";

export default class UserSessionDomain extends R14.Domain {
  constructor() {
    super();
    this.AUTH_STATE_LOGGED_OUT = "LOGGED_OUT";
    this.AUTH_STATE_LOGGED_IN = "LOGGED_IN";
    this.AUTH_STATE_VERIFY_MFA_CODE = "VERIFY_MFA_CODE";
    this.AUTH_STATE_UPDATE_PASSWORD = "UPDATE_PASSWORD";
    this.state = {
      uid: null,
      // username: null,
      name: null,
      clientUid: null,
      clientName: null,
      loggedIn: null,
      role: null,
      loggedIn: false,
      loaded: false,
      authState: this.STATE_LOGGED_OUT,
      mfaAccessToken: null,
      permissions: {},
    };
  }
  async domainDidLoad() {
    return await this.load();
  }

  async load() {
    let loggedIn = await this.api.accessTokenExists();

    if (loggedIn) {
      try {
        await this.authR14Login();
      } catch (err) {
        console.error(err);
        this.redirectToR14Login();
      }
      return true;
    }

    let userState = {
      uid: (await AsyncStorage.getItem("uid")) || null,
      // username: (await AsyncStorage.getItem("username")) || null,
      name: (await AsyncStorage.getItem("name")) || null,
      clientUid: (await AsyncStorage.getItem("clientUid")) || null,
      clientName: (await AsyncStorage.getItem("clientName")) || null,
      role: (await AsyncStorage.getItem("role")) || null,
      loggedIn,
      loaded: true,
      mfaAccessToken:
        (await AsyncSessionStorage.getItem("mfaAccessToken")) || "",
      permissions: {},
    };

    // Calculate the auth state
    userState.authState = loggedIn
      ? this.AUTH_STATE_LOGGED_IN
      : userState.mfaAccessToken
      ? this.AUTH_STATE_VERIFY_MFA_CODE
      : this.AUTH_STATE_LOGGED_OUT;
    // for (let i in userState) {
    //   promises.push(userState[i]);
    // }
    // await Promise.all(promises);
    if (userState.loggedIn && userState.uid) {
      // Get permissions
      try {
        userState.permissions = await this.dm.user.fetchPermissions(
          userState.uid
        );
      } catch (err) {
        console.error("User Error:", err);
        await this.logout();
        //this.nav.to("login");
      }
    }
    this.setState(userState);
    return true;
  }
  async handleAuthError() {
    let f = this.nav.getCurrentRouteUri();
    await this.logout();
    if (this.redirectToR14Login()) return false;
    else this.nav.load("login", { f });
  }
  async handleClientError(clientError) {
    let toRoute = null;
    let params = {};
    switch (clientError) {
      case this.api.RESPONSE_ERROR_UNAUTHORIZED:
        toRoute = "login";
        params.f = this.nav.getCurrentRouteUri();
        await this.logout();
        break;
      case this.api.RESPONSE_ERROR_FORBIDDEN:
        toRoute = "forbidden";
        await this.logout();
        break;
      default:
        toRoute = "error";
        break;
    }

    this.nav.load(toRoute, params);

    // route.path &&
    //     route.name &&
    //     !["login", "loginVerify", "landing"].includes(route.name)
    //     ? params
    //     : null
  }
  // MFA Code Verification
  async resendMfaCode() {
    let mfaAccessToken = this.mfaAccessToken;
    if (!mfaAccessToken) throw new Error("Token not found.");
    let res = await this.api.mutate(
      `
        mutation ResendUserMfaCode {
          resendUserMfaCode {
            success
            error
          }
       }`,
      {},
      { accessToken: mfaAccessToken }
    );
    console.log("CHECK RESEND", res.data, res.errors);
    return true;
  }
  // MFA Code Verification
  async verifyMfaCode({ mfaCode }) {
    let mfaAccessToken = this.mfaAccessToken;
    if (!mfaAccessToken) throw new Error("Token not found.");
    let res = await this.api.mutate(
      `
        mutation VerifyUserMfaCode($input: VerifyUserMfaCodeInput!) {
          verifyUserMfaCode(input: $input){
            user {
              uid
              name
              role
              client {
                uid
                name
              }
              userPermissionGroup {
                uid
                name
                permissions {
                  type
                  subtype
                  access
                }
              }
            }
            state
            accessToken
            success
            error
          }
       }`,
      {
        input: {
          mfaCode,
        },
      },
      { accessToken: mfaAccessToken }
    );
    if (
      res.data &&
      res.data.verifyUserMfaCode &&
      res.data.verifyUserMfaCode.error
    )
      throw new Error(res.data.verifyUserMfaCode.error);
    else if (res.error && res.errors.length) {
      throw new Error(res.error[1]);
    } else if (!res.data.verifyUserMfaCode) {
      throw new Error("Unknown error");
    }
    return await this._processAuthResult(res.data.verifyUserMfaCode);
  }

  async logoutR14Login() {
    let res = await this.api.mutate(
      `
        mutation LogoutR14LoginUser {
          logoutR14LoginUser {
            success
            error
          }
        }`,
      {},
      { ignoreAccessTokenRefresh: true, accessToken: false }
    );
    if (
      res.data &&
      res.data.logoutR14LoginUser &&
      res.data.logoutR14LoginUser.error
    )
      throw new Error(res.data.logoutR14LoginUser.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return res.data.logoutR14LoginUser;
  }

  async authR14Login() {
    let res = await this.api.mutate(
      `
        mutation AuthR14LoginUser {
          authR14LoginUser{
            user {
              uid
              name
              role
              client {
                uid
                name
              }
              userPermissionGroup {
                uid
                name
                permissions {
                  type
                  subtype
                  access
                }
              }
            }
            state
            accessToken
            accessTokenExpiresMinutes
            success
            error
          }
       }`,
      {},
      { ignoreAccessTokenRefresh: true, accessToken: false }
    );

    let error = null;
    if (
      res.data &&
      res.data.authR14LoginUser &&
      res.data.authR14LoginUser.error
    )
      error = res.data.authR14LoginUser.error;
    else if (res.errors && res.errors.length) error = res.errors[0].message;
    if (error) console.error(error);
    if (!res.data.authR14LoginUser) throw new Error("No user found");
    return await this._processAuthResult(res.data.authR14LoginUser);
  }
  async auth({ email, password }) {
    await this.logout();
    let res = await this.api.mutate(
      `
        mutation AuthUser($input: AuthUserInput!) {
          authUser(input: $input){
            user {
              uid
              name
              role
              client {
                uid
                name
              }
              userPermissionGroup {
                uid
                name
                permissions {
                  type
                  subtype
                  access
                }
              }
            }
            state
            accessToken
            success
            error
          }
       }`,
      {
        input: {
          email: email,
          password: password,
        },
      }
    );
    if (res.data && res.data.authUser && res.data.authUser.error)
      throw new Error(res.data.authUser.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return await this._processAuthResult(res.data.authUser);
  }

  async switchClient(clientUid) {
    let res = await this.api.mutate(
      `
       mutation SwitchUserClient($clientUid: ID!) {
         switchUserClient(clientUid: $clientUid){
           user {
             uid
             name
             role
             client {
               uid
               name
             }
             userPermissionGroup {
              uid
              name
              permissions {
                type
                subtype
                access
              }
            }
           }
          state
          accessToken
          success
          error
         }
       }`,
      {
        clientUid: clientUid,
      }
    );
    if (res.error) throw new Error(res.error);
    return await this._processAuthResult(res.switchUserClient);
  }
  async _processAuthResult(res) {
    if (
      !res.user ||
      !res.accessToken ||
      !res.state ||
      res.state === this.AUTH_STATE_LOGGED_OUT
    ) {
      if (this.isLoggedIn) await this.logout();
      throw new Error("Unknown Auth Error: User not logged in.");
    }
    let user = res.user;
    if (!user || !user.uid) new Error("Unknown Auth Error: User not found.");
    if (!user.client || !user.client.uid)
      new Error("Unknown Auth Error: Client not found.");

    switch (res.state) {
      case this.AUTH_STATE_LOGGED_IN:
        await this.api.setAccessToken(res.accessToken);
        if (!(await this.api.accessTokenExists()))
          throw new Error("Access token not found");
        if (res.accessTokenExpiresMinutes) {
          let expiresAt = new Date();
          expiresAt = expiresAt.setMinutes(
            expiresAt.getMinutes() + res.accessTokenExpiresMinutes
          );
          this.api.setAccessTokenRefresh(expiresAt, async () => {
            try {
              await this.authR14Login();
            } catch (err) {
              console.error(err);
              this.redirectToR14Login();
            }
            return true;
          });
        }
        this.setUid(user.uid);
        this.setName(user.name);
        this.setClientUid(user.client.uid);
        this.setClientName(user.client.name);
        this.setRole(user.role);
        this.setLoggedIn(true);
        this.setPermissions(
          this.dm.user.parsePermissions(user.userPermissionGroup.permissions)
        );
        this.setAuthState(this.AUTH_STATE_LOGGED_IN);
        await this.deleteMfaAccessToken();
        break;
      case this.AUTH_STATE_VERIFY_MFA_CODE:
        this.setUid(user.uid);
        this.setName(user.name);
        this.setClientUid(user.client.uid);
        this.setClientName(user.client.name);
        this.setRole(user.role);
        this.setLoggedIn(false);
        this.setPermissions(null);
        this.setAuthState(this.AUTH_STATE_VERIFY_MFA_CODE);
        this.setMfaAccessToken(res.accessToken);
        break;
      case this.AUTH_STATE_UPDATE_PASSWORD:
        throw new Error("UPDATE PASSWORD");
        break;
    }

    return res.state || this.AUTH_STATE_LOGGED_OUT;
  }
  async logout(options = {}) {
    let loggedIn = this.isLoggedIn ? true : false;
    // Remove login token
    await this.api.deleteAccessToken();
    this.setLoggedIn(false);
    await AsyncStorage.clear();
    await AsyncSessionStorage.clear();
    if (loggedIn && options.logoutR14Login !== false)
      await this.logoutR14Login();
    return true;
  }
  async setUid(uid) {
    AsyncStorage.setItem("uid", uid);
    this.setState({ uid: uid });
  }
  get uid() {
    return this.state.uid;
  }
  async setRole(role) {
    AsyncStorage.setItem("role", role);
    this.setState({ role: role });
  }
  get role() {
    return this.state.role;
  }
  get permissions() {
    return this.state.permissions;
  }
  setPermissions(permissions) {
    this.setState({ permissions: permissions });
  }
  setName(name) {
    AsyncStorage.setItem("name", name);
    this.setState({ name: name });
  }
  get name() {
    return this.state.name;
  }
  // setUsername(username) {
  //   AsyncStorage.setItem('username', username);
  //   this.setState({ username: username });
  // }
  // get username() {
  //   return this.state.username
  // }
  setClientUid(clientUid) {
    AsyncStorage.setItem("clientUid", clientUid);
    this.setState({ clientUid: clientUid });
  }
  get clientUid() {
    return this.state.clientUid;
  }
  setClientName(clientName) {
    AsyncStorage.setItem("clientName", clientName);
    this.setState({ clientName: clientName });
  }
  get clientName() {
    return this.state.clientName;
  }
  setLoggedIn(loggedIn) {
    this.setState({
      loggedIn: loggedIn,
    });
  }
  get authState() {
    return this.state.authState;
  }
  setAuthState(authState) {
    this.setState({ authState });
  }
  get mfaAccessTokenExists() {
    return this.state.mfaAccessToken ? true : false;
  }
  get mfaAccessToken() {
    return this.state.mfaAccessToken;
  }
  setMfaAccessToken(mfaAccessToken) {
    AsyncSessionStorage.setItem("mfaAccessToken", mfaAccessToken || "");
    this.setState({ mfaAccessToken });
  }
  async deleteMfaAccessToken() {
    await AsyncSessionStorage.removeItem("mfaAccessToken");
    this.setState({ mfaAccessToken: undefined });
  }
  get isLoggedIn() {
    return this.state.loggedIn;
  }
  get isLoaded() {
    return this.state.loaded;
  }
  redirectToR14Login(path = "", params = {}) {
    if (!path && process.env.NODE_ENV === "development") {
      console.log("R14 FORWARD DISABLED FOR DEV.");
      return false;
    }
    if (typeof path === "object") {
      params = path;
      path = "";
    }
    if (
      this.r14.config &&
      this.r14.config.metadata &&
      this.r14.config.metadata.r14Login &&
      this.r14.config.metadata.r14Login.url
    ) {
      this.nav.toUrl(`${this.r14.config.metadata.r14Login.url}${path}`);
      return true;
    }
    return false;
  }
}
