import R14 from "../core";
export default class ManualEntryUserBatchDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryUserBatch",
    });
    this.CSV_HEADER_COLUMN_NAME= "NAME";
      this.CSV_HEADER_COLUMN_USERNAME= "USERNAME";
      this.CSV_HEADER_COLUMN_EMAIL= "EMAIL";
      this.CSV_HEADER_COLUMN_PHONE= "PHONE";
      this.CSV_HEADER_COLUMN_PASSWORD= "PASSWORD";
      this.CSV_HEADER_COLUMN_MANUAL_ENTRY_CONFIG_KEY= "MANUAL_ENTRY_CONFIG_KEY";
      this.CSV_HEADER_COLUMN_MANUAL_ENTRY_MODES= "MANUAL_ENTRY_MODES";
      this.CSV_HEADER_COLUMN_VIRTUAL_WORKSPACE_CONFIG_KEY=
        "VIRTUAL_WORKSPACE_CONFIG_KEY";
      this.CSV_HEADER_COLUMN_COUNTRY_CODE= "COUNTRY_CODE";
      this.CSV_HEADER_COLUMN_CURRENCY_CODE= "CURRENCY_CODE";
      this.CSV_HEADER_COLUMN_MFA_TYPE= "MFA_TYPE";
      this.CSV_HEADER_COLUMN_ACTIVE= "ACTIVE";
      this.CSV_HEADER_COLUMN_SOURCE= "SOURCE";
      this.STATE_INIT= "INIT";
      this.STATE_RUNNING= "RUNNING";
      this.STATE_COMPLETE= "COMPLETE";
      this.STATE_ERROR= "ERROR";
  }
  async fetchEditFormData(uid = null, options = {}) {
    let fields = `
  uid
  name
  description
`;
    let formDataValues = {};
    if (uid) {
      let res = await this.get(uid, fields);
      formDataValues = {
        uid: res.uid,
        name: res.name,
      };
    }
    return {
      values: formDataValues,
    };
  }
  parseSubmitValues(values) {
    values.clientUid = this.dm.userSession.clientUid;
    values.userUid = this.dm.userSession.uid;
    console.log("CHECK VAQLS", values);
    return values;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_INIT:
        label = "Initializing";
        break;
      case this.STATE_RUNNING:
        label = "Running";
        break;
      case this.STATE_COMPLETE:
        label = "Complete";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_INIT:
      case this.STATE_RUNNING:
        color = "yellow";
        break;
      case this.STATE_COMPLETE:
        color = "green";
        break;
      case this.STATE_ERROR:
        color = "red";
        break;
    }
    return color;
  }
}
