import R14 from "../core";

export default class ManualEntryConfigDomain extends R14.Domain {
  constructor(key) {
    super();
    this.state = {};
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";
    this.COMMAND_TIMEOUT = "TIMEOUT";
    this.COMMAND_RESTART_SERVICES = "RESTART_SERVICES";
    this.PRICING_MODE_FIXED = "FIXED";
    this.PRICING_MODE_AVERAGE = "AVERAGE";
    this.PRICING_UPDATE_FREQUENCY_TYPE_INTERVAL_MINUTES = "INTERVAL_MINUTES";
    this.PRICING_RANGE_TYPE_PAST_DAYS = "PAST_DAYS";
    this.PRICING_STATE_UPDATE = "UPDATE";
    this.DOCUMENT_SCALE_MODE_COVER = "COVER";
    this.DOCUMENT_SCALE_MODE_CONTAIN = "CONTAIN";
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindManualEntryConfigs($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ManualEntryConfigFilter) {
        manualEntryConfigs(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.manualEntryConfigs;
  }
  async get(uid, options = {}) {
    let projectField = "";
    let qryParams = ["$uid: ID!"];
    let qryVals = {
      uid: uid,
    };

    if (options.project) {
      projectField = `
    project {
      key
      uid
      name
      type
    }
    `;
    }

    let res = await this.api.qry(
      `
      query GetManualEntryConfig(${qryParams.join(", ")}) {
       manualEntryConfig(uid: $uid){
          uid
          name
          pipelineBlockUids
          ${projectField}
        }
      }`,
      qryVals
    );
    return res.data.manualEntryConfig;
  }

  // async initDashboard(uid) {
  //   let res = await this.api.qry(
  //     `
  //     query InitManualEntryDashboard($uid: ID!) {
  //      manualEntryConfig(uid: $uid){
  //         uid
  //         name
  //         project {
  //           uid
  //           name
  //           type
  //         }
  //       }
  //       manualEntryDocumentCurrentMetrics(manualEntryConfigUid: $uid){
  //         total
  //         totalAvailable
  //         totalQueued
  //       }
  //       manualEntryUserCurrentMetrics(manualEntryConfigUid: $uid){
  //         totalActive
  //       }
  //       manualEntryFieldCurrentMetrics(manualEntryConfigUid: $uid){
  //         total
  //         totalAvailable
  //         totalQueued
  //       }
  //       manualEntryCharacterCurrentMetrics(manualEntryConfigUid: $uid){
  //         total
  //         totalAvailable
  //         totalQueued
  //       }
  //     }`,
  //     {
  //       uid: uid,
  //     }
  //   );
  //   let ret = res.data.manualEntryConfig;
  //   ret.metrics = {
  //     documents: res.data.manualEntryDocumentCurrentMetrics,
  //     users: res.data.manualEntryUserCurrentMetrics,
  //     characters: res.data.manualEntryCharacterCurrentMetrics,
  //     fields: res.data.manualEntryFieldCurrentMetrics,
  //   };
  //   return ret;
  // }

  // getPricingMetricFields() {
  //   return [
  //     "cmtc",
  //     "cmtcu",
  //     "cmtcr",
  //     "fcmtc",
  //     "fcmtcu",
  //     "fcmtcr",
  //     "fcmt",
  //     "fcmtfu",
  //     "fcmtfr",
  //     "fmt",
  //     "fmtfu",
  //     "fmtfr",
  //   ];
  // }
  // getPricingMetricFieldInfo() {
  //   return [
  //     this.dm.manualEntry.MODE_CHARACTERS,
  //     this.dm.manualEntry.MODE_FIELD_CHARACTERS,
  //     this.dm.manualEntry.MODE_FIELD,
  //   ].map((mode) => {
  //     let prefix = null;
  //     let label = null;
  //     switch (mode) {
  //       case this.dm.manualEntry.MODE_CHARACTERS:
  //         prefix = "cm";
  //         label = "Characters";
  //         break;
  //       case this.dm.manualEntry.MODE_FIELD_CHARACTERS:
  //         prefix = "fcm";
  //         label = "Field Characters";
  //         break;
  //       case this.dm.manualEntry.MODE_FIELD:
  //         prefix = "fm";
  //         label = "Fields";
  //         break;
  //     }
  //     let fields = this.dm.manualEntry.getPricingMetricFields()
  //       .filter((field) => field.startsWith(prefix))
  //       .map((name) => {
  //         let type = name.substring(prefix.length);
  //         let label = null;
  //         switch (type) {
  //           case "tc":
  //             label = "Total Characters";
  //             break;
  //           case "tcu":
  //             label = "Updated Characters";
  //             break;
  //           case "tcr":
  //             label = "Rejected Characters";
  //             break;
  //           case "t":
  //             label = "Total Fields";
  //             break;
  //           case "tfu":
  //             label = "Updated Fields";
  //             break;
  //           case "tfr":
  //             label = "Rejected Field";
  //             break;
  //         }
  //         return {
  //           name,
  //           label,
  //         };
  //       });
  //     return { mode, label, fields };
  //   });
  // }

  async fetchEditFormData(uid = null) {
    let qryVals = {
      // pipelineFilter: {
      //   clientUid: { eq: this.dm.userSession.clientUid },
      // },
      // pipelineSort: [
      //   {
      //     field: "name",
      //     order: "ASC",
      //   },
      // ],
      // projectFilter: {
      //   clientUid: { eq: this.dm.userSession.clientUid },
      //   type: { eq: this.dm.project.TYPE_IDP },
      // },
      // projectSort: [
      //   {
      //     field: "name",
      //     order: "ASC",
      //   },
      // ],
      manualEntryPipelineFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      manualEntryPipelineSort: [
        {
          field: "name",
          order: "ASC",
        },
      ],
      manualEntryPricingConfigFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      manualEntryPricingConfigSort: [
        {
          field: "name",
          order: "ASC",
        },
      ],
    };
    // if (!this.dm.user.hasAdminRole)
    //   qryVals.pipelineFilter["userUids"] = { eq: this.dm.userSession.uid };

    if (uid) {
      qryVals.uid = uid;
      // qryVals.manualEntryPipelineSort = {
      //   field: "name",
      //   order: "ASC",
      // };
      // qryVals.manualEntryPipelineFilter = {};
      // if (!this.dm.user.hasAdminRole)
      //   qryVals.pipelineBlockFilter = {
      //     userUids: { eq: this.dm.userSession.uid },
      //   };
    }

    let res = uid
      ? await this.api.qry(
          `
          query ManualEntryConfigEditFormData($uid: ID!, $manualEntryPipelineFilter: ManualEntryPipelineFilter, $manualEntryPipelineSort: [SortOption!]!) {
            manualEntryConfig(uid: $uid){
              uid
              name
              key
              description
              modes
              itemsPerQueue
              charactersPerItem
              queuedTimeoutSeconds
              reviewTimeoutSeconds
              accuracyTestProbability
              active
              manualEntryPipelineUids  
              queueNamespaces  
              exclusiveQueueNamespaces
              ndaAcceptRequired
              enableDocumentCopy
              enableDocumentMove
              enableDocumentRotate
              disableReject
              disableRejectRemaining
              disableAcceptRemaining
              disableDocumentPreview
              initialDocumentScaleMode
            }
            manualEntryPipelines (filter: $manualEntryPipelineFilter, sort: $manualEntryPipelineSort) {
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        )
      : await this.api.qry(
          `
          query ManualEntryConfigCreateFormData( $manualEntryPipelineFilter: ManualEntryPipelineFilter, $manualEntryPipelineSort: [SortOption!]!) {
            manualEntryPipelines (filter: $manualEntryPipelineFilter, sort: $manualEntryPipelineSort) {
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        );

    let formVals = {};
    let formData = {
      values: {},
      pipelineMap: {},
      modeSelections: [
        {
          label: "Characters",
          value: this.MODE_CHARACTERS,
        },
        {
          label: "Field",
          value: this.MODE_FIELD,
        },
        {
          label: "Field Characters",
          value: this.MODE_FIELD_CHARACTERS,
        },
        {
          label: "Document Set Fields",
          value: this.MODE_DOCUMENT_SET_FIELDS,
        },
      ],
      initialDocumentScaleModeSelections: [
        {
          label: "Cover",
          value: this.DOCUMENT_SCALE_MODE_COVER,
        },
        {
          label: "Contain",
          value: this.DOCUMENT_SCALE_MODE_CONTAIN,
        },
      ],
      appFeatureSelections: this.getAppFeatureSelections(),
      manualEntryPipelineSelections:
        res.data.manualEntryPipelines && res.data.manualEntryPipelines.nodes
          ? res.data.manualEntryPipelines.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
      manualEntryPricingConfigSelections:
        res.data.manualEntryPricingConfigs &&
        res.data.manualEntryPricingConfigs.nodes
          ? res.data.manualEntryPricingConfigs.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
      // pipelineSelections:
      //   res.data.pipelines && res.data.pipelines.nodes
      //     ? res.data.pipelines.nodes.map((val) => ({
      //         label: val.name,
      //         value: val.uid,
      //       }))
      //     : [],
      // projectSelections:
      //   res.data.projects && res.data.projects.nodes
      //     ? res.data.projects.nodes.map((val) => ({
      //         label: val.name,
      //         value: val.uid,
      //       }))
      //     : [],
    };
    if (res.data && res.data.manualEntryConfig) {
      // if (res.data.pipelines && res.data.pipelines.nodes) {
      //   res.data.pipelines.nodes.forEach((pipeline) => {
      //     formData.pipelineMap[pipeline.uid] = {
      //       uid: pipeline.uid,
      //       name: pipeline.name,
      //       pipelineBlocks: {},
      //     };
      //   });
      // }
      formVals = res.data.manualEntryConfig || {};

      if (!formVals.initialDocumentScaleMode)
        formVals.initialDocumentScaleMode = this.DOCUMENT_SCALE_MODE_COVER;

      if (formVals.queueNamespaces) {
        formVals.queueNamespaces = formVals.queueNamespaces.map((n) => ({
          queueNamespace: n,
        }));
      }

      // Update the app feature values
      formVals.appFeatures = [];
      formData.appFeatureSelections.forEach((f) => {
        if (f.value in formVals) {
          formVals[f.value] && formVals.appFeatures.push(f.value);
          delete formVals[f.value];
        }
      });

      // formVals = manualEntryConfig ? { ...formVals, ...manualEntryConfig } : {};
      // let pipelineBlocks =
      //   manualEntryConfig &&
      //   manualEntryConfig.pipelineBlocks &&
      //   manualEntryConfig.pipelineBlocks.nodes
      //     ? manualEntryConfig.pipelineBlocks.nodes
      //     : [];

      // pipelineBlocks.forEach((pipelineBlock) => {
      //   if (!formData.pipelineMap[pipelineBlock.pipelineUid]) return;
      //   formData.pipelineMap[pipelineBlock.pipelineUid].pipelineBlocks[
      //     pipelineBlock.uid
      //   ] = { uid: pipelineBlock.uid, name: pipelineBlock.name };
      //   formVals.pipelineBlocks.push({
      //     pipelineBlockUid: pipelineBlock.uid,
      //     pipelineUid: pipelineBlock.pipelineUid,
      //   });
      // });
    }
    formData.values = formVals;
    return formData;
  }
  async fetchSelections(filters, options = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    // if (options.currencyUid) {
    //   filter.currencyUid = { neq: null };
    // }
    // let currencyUidField = options.currencyUid ? 'currencyUid' : '';
    let res = await this.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: options.resultsPerPage || 25,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "ASC",
          },
        ],
        clientUid: false,
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => {
            let ret = {
              label: val.name,
              value: val.uid,
            };
            if (options.currencyUid) ret.currencyUid = val.currencyUid;
            return ret;
          })
        : [];
    return ret;
  }
  async create(values) {
    let res = await this.api.mutate(
      `
      mutation CreateManualEntryConfig($input: CreateManualEntryConfigInput!) {
        createManualEntryConfig(input: $input){
          manualEntryConfig {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateManualEntryConfig($input: UpdateManualEntryConfigInput!) {
        updateManualEntryConfig(input: $input){
          manualEntryConfig {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );

    return true;
  }
  parseFormValues(values) {
    let ret = {
      ...values,
      clientUid: this.dm.userSession.clientUid,
      reviewTimeoutSeconds: parseInt(values.reviewTimeoutSeconds),
      queuedTimeoutSeconds: parseInt(values.queuedTimeoutSeconds),
      itemsPerQueue: parseInt(values.itemsPerQueue),
      charactersPerItem: parseInt(values.charactersPerItem),
      accuracyTestProbability: !isNaN(values.accuracyTestProbability)
        ? parseFloat(values.accuracyTestProbability)
        : null,
      queueNamespaces: values.queueNamespaces
        ? values.queueNamespaces.map((n) => n.queueNamespace)
        : null,
    };

    // Add App Options
    if (ret.appFeatures) {
      this.getAppFeatureSelections().forEach((f) => {
        ret[f.value] = ret.appFeatures.includes(f.value) ? true : false;
      });
      delete ret.appFeatures;
    }
    return ret;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteManualEntryConfig($uid: ID!) {
        deleteManualEntryConfig(uid: $uid){
          user {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  getAppFeatureSelections() {
    return [
      { value: "ndaAcceptRequired", label: "NDA Required" },

      {
        value: "enableDocumentCopy",
        label: "Document Copy",
      },
      {
        value: "enableDocumentMove",
        label: "Document Move",
      },
      {
        value: "enableDocumentRotate",
        label: "Document Rotate",
      },
      {
        value: "disableDocumentPreview",
        label: "Disable Document Preview",
      },
      { value: "disableReject", label: "Disable Reject" },
      {
        value: "disableRejectRemaining",
        label: "Disable Reject Remaining",
      },
      {
        value: "disableAcceptRemaining",
        label: "Disable Accept Remaining",
      },
    ];
  }
  getPricingModeLabel(pricingMode) {
    let ret = null;
    switch (pricingMode) {
      case this.PRICING_MODE_AVERAGE:
        ret = "Dynamic (per hour)";
        break;
      case this.PRICING_MODE_FIXED:
        ret = "Fixed (per unit)";
        break;
    }
    return ret;
  }
}
