// import HeaderControlsLeft from '../layout/HeaderControlLeft';
import HeaderControlsRight from "../components/HeaderControlsRight";
import HeaderControlsLeft from "../components/HeaderControlsLeft";
import DrawerHeader from "../components/DrawerHeader";
// import Drawer from "../components/Drawer";
import { Colors, StyleSheet } from "../core";
const findInitialRoute = (initialRoute, navigator, app) => {
  let ret = initialRoute;
  // Check initial route
  if (
    checkRoutePermissions({
      route: navigator.routes[initialRoute],
      app,
    })
  ) {
    return initialRoute;
  }
  for (let routeName in navigator.routes) {
    if (
      checkRoutePermissions({
        route: navigator.routes[routeName],
        app,
      })
    ) {
      ret = routeName;
      break;
    }
  }
  return ret;
};
const checkRoutePermissions = ({ route, app }) => {
  if (!route.metadata && route.routes) {
    for (let routeName in route.routes) {
      let ret = checkRoutePermissions({
        route: route.routes[routeName],
        app,
      });
      if (ret) return true;
    }
    return false;
  } else if (route.metadata && route.metadata.permissions) {
    let perms = route.metadata.permissions;
    // if (
    //   !app.dm.user.checkPermissions(
    //     perms.type,
    //     perms.subtype,
    //     perms.access || app.dm.user.PERMISSION_ACCESS_READ
    //   )
    // )
    //   console.log("HAS PERMS!", route);
    return app.dm.user.checkPermissions(
      perms.type,
      perms.subtype,
      perms.access || app.dm.user.PERMISSION_ACCESS_READ
    );
  } else return true;
};
const styles = StyleSheet.create({
  // drawerItem: {
  //   backgroundColor: Colors.secondary,
  //   borderRadius: 0,
  // },
  // drawerItemLabelText: {
  //   color: Colors.primary,
  // },
  // drawerItemIcon: {
  //   color: Colors.primary,
  // },
  // drawerItemActive: {
  //   backgroundColor: Colors.primary,
  //   borderRadius: 16,
  // },
  // drawerItemActiveLabelText: {
  //   color: Colors.secondary,
  // },
  // drawerItemActiveIcon: {
  //   color: Colors.secondary,
  // },
  manualEntryPricingConfigEditDialog: {
    maxWidth: 800,
    // screen: ({ width }) => ({
    //   maxWidth: 640,
    // }),
  },
  childTabNavigator: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.06),
  },
  childTabNavigatorItem: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
  },
  childTabNavigatorActiveItem: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.04),
    borderColor: Colors.secondaryLight,
  },
});

export default () => ({
  initialRoute: "main",
  routes: {
    main: {
      initialRoute: "admin",
      type: "stack",
      header: {
        // title: 'R14 Dev App',
        controlsLeft: HeaderControlsLeft,
        controlsRight: HeaderControlsRight,
      },
      routes: {
        landing: {
          path: "/",
          metadata: {
            public: true,
          },
        },
        login: {
          path: "/login",
          metadata: {
            public: true,
          },
        },
        auth: {
          path: "/auth",
          metadata: {
            public: true,
          },
        },
        forbidden: {
          path: "/forbidden",
          metadata: {
            public: true,
          },
        },
        error: {
          path: "/error",
          metadata: {
            public: true,
          },
        },
        loginVerify: {
          path: "/login/verify",
          metadata: {
            public: true,
          },
        },
        manualEntryLogin: {
          path: "/entry/login",
          metadata: {
            public: true,
          },
        },
        manualEntryLogout: {
          path: "/entry/logout",
          metadata: {
            public: true,
          },
        },
        r14LicenseAdminConfigEdit: {
          path: `/r14/license/admin/config`,
          label: "R14 License Config",
          metadata: {
            public: true,
          },
        },
        ideNavigator: {
          routes: {
            projectAppModuleIde: {
              path: "/:key/project/module/ide/:uid/:appModuleUid",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "appModule",
                  access: "edit",
                },
              }),
              options: {
                drawer: {
                  type: "modal",
                },
              },
            },
            projectPipelineBlockIde: {
              title: "Edit Pipeline Block",
              path: "/:key/project/pipeline/block/ide/:uid/:pipelineUid/:pipelineBlockUid",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "appModule",
                  access: "edit",
                },
              }),
            },
          },
        },
        homeNavigator: {
          // type: 'tab',
          // tabNavigator: {
          //   position: 'top',
          //   showIcon: false,
          //   showLabel: true,
          //   upperCaseLabel: true,
          //   scrollEnabled: true
          // },
          type: "drawer",
          drawerNavigator: {
            modalHeader: DrawerHeader,
            position: "left",
            showIcon: true,
            showLabel: true,
            upperCaseLabel: false,
            scrollEnabled: true,
            type: "responsive",
            //contentComponent: Drawer,
            itemRenderer: checkRoutePermissions,
            // itemStyle: styles.drawerItem,
            // itemIconColor: Colors.primary,
            // itemLabelTextStyle: styles.drawerItemLabelText,
            // itemActiveStyle: styles.drawerItemActive,
            // itemActiveIconColor: Colors.secondary,
            // itemActiveLabelTextStyle: styles.drawerItemActiveLabelText,
          },
          initialRoute: "projects",
          routes: {
            userPermissionsError: {
              path: "/user/permissions/error",
              visible: false,
            },
            rootProjectNavigator: {
              type: "stack",
              initialRoute: "rootProjects",
              visible: false,
              routes: {
                rootProjects: {
                  path: `/projects`,
                  label: "Projects",
                  metadata: {
                    permissions: {
                      type: "project",
                    },
                  },
                  //action: 'projects',
                },
                rootProject: {
                  title: "Project",
                  path: "/project/:uid",
                  metadata: {
                    permissions: {
                      type: "project",
                    },
                  },
                },
              },
            },
            // rootProjects: {
            //   label: "Projects",
            //   path: `/projects`,
            //   icon: 'accountGroup',
            //   // metadata: ({ route }) => ({
            //   //   permissions: {
            //   //     type: route.params.key,
            //   //   },
            //   // }),
            // },
            resourcesNavigator: {
              type: "stack",
              visible: false,
              initialRoute: "resources",
              // label: "Cloud",
              // icon: "cloud",
              routes: {
                // resources: {
                //   path: `/resources`,
                //   label: "Resources",
                //   metadata: {
                //     permissions: {
                //       type: "resource"
                //     }
                //   }
                //   //action: 'resources',
                // },
                // resourceNavigator: {
                //   type: "stack",
                //   initialRoute: "resourceTabNavigator",
                //   header: ResourceHeader,
                //   routes: {
                resourceTabNavigator: {
                  type: "tab",
                  //header: ResourceHeader,
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                    onWillBlur: (navigator, app) => {
                      app.dm.resource.clearInstances();
                    },
                  },

                  initialRoute: "resource",
                  routes: {
                    resource: {
                      label: "Dashboard",
                      path: "/resource/:uid",
                      metadata: {
                        permissions: {
                          type: "resource",
                        },
                      },
                    },
                    resourceMonitor: {
                      label: "Monitor",
                      path: "/resource/monitor/:uid",
                      metadata: {
                        permissions: {
                          type: "resource",
                          subtype: "monitor",
                        },
                      },
                    },
                    resourceActivityLog: {
                      path: `/resource/activity/:uid`,
                      label: "Activity Log",
                      metadata: {
                        permissions: {
                          type: "resource",
                          subtype: "activityLog",
                        },
                      },
                    },
                    resourceEventTasks: {
                      path: `/resource/events/:uid`,
                      label: "Events",
                      icon: "calendarClock",
                      metadata: {
                        permissions: {
                          type: "resource",
                          subtype: "event",
                        },
                      },
                    },
                    resourceScripts: {
                      path: `/resource/scripts/:uid`,
                      label: "Scripts",
                      icon: "codeBraces",
                      metadata: {
                        permissions: {
                          type: "resource",
                          subtype: "script",
                        },
                      },
                    },
                    resourceLogs: {
                      path: `/resource/logs/:uid`,
                      label: "Remote Logs",
                      icon: "console",
                      metadata: {
                        permissions: {
                          type: "resource",
                          subtype: "log",
                        },
                      },
                    },
                    // resourceAlerts: {
                    //   path: `/resource/alerts/:uid`,
                    //   label: "Alerts",
                    //   icon: "notificationsNone",
                    //   metadata: {
                    //     permissions: {
                    //       type: "resource",
                    //       subtype: "alert",
                    //     },
                    //   },
                    // },
                    resourceStatusChecks: {
                      path: `/resource/statusChecks/:uid`,
                      label: "Status Checks",
                      icon: "heartPulse",
                      metadata: {
                        permissions: {
                          type: "resource",
                          subtype: "statusCheck",
                        },
                      },
                    },
                  },
                },
              },
              //  }
              //}
            },
            // messagesNavigator: {

            //   label: DrawerMessagesLabel,
            //   icon: "message",
            //   type: "stack",
            //   routes: {
            //     messages: {
            //       path: "/messages"
            //     },
            //     messagesConversation: {
            //       path: "/messages/conversation/:uid"
            //     }
            //   }
            //   //action: 'events',
            // },
            // todosNavigator: {
            //   label: 'To Do',
            //   icon: "formatListCheckbox",
            //   type: "tab",
            //   initialRoute: 'todos',
            //   tabNavigator: {
            //     position: "top",
            //     showIcon: false,
            //     showLabel: true,
            //     upperCaseLabel: true,
            //     scrollEnabled: true
            //   },
            //   routes: {
            //     todosBoard: {
            //       label: "Board",
            //       path: '/todos/board'
            //     },
            //     todosActive: {
            //       label: "To Do",
            //       path: '/todos/active'
            //     },
            //     todosProgress: {
            //       label: "In Progress",
            //       path: '/todo/inProgress'
            //     },
            //     todosReview: {
            //       label: "In Review",
            //       path: '/todo/inReview'
            //     },
            //     todosComplete: {
            //       label: "Completed",
            //       path: '/todo/complete'
            //     }
            //   }
            // },

            projectsRootNavigator: {
              type: "stack",
              initialRoute: "projects",
              visible: false,
              routes: {
                projectManualEntryConfigNavigator: {
                  type: "tab",
                  //header: ResourceHeader,
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                  },
                  initialRoute: "projectManualEntryConfig",
                  routes: {
                    // projectManualEntryConfig: {
                    //   label: "Dashboard",
                    //   path:
                    //     "/:key/project/entry/config/:uid/:manualEntryConfigUid",
                    //   metadata: ({ route }) => ({
                    //     permissions: {
                    //       type: route.params.key,
                    //       subtype: "manualEntryConfig",
                    //     },
                    //   }),
                    // },
                    projectManualEntryPipeline: {
                      label: "Dashboard",
                      path: "/:key/project/entry/:uid/:manualEntryPipelineUid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "manualEntryPipeline",
                        },
                      }),
                    },
                    projectManualEntryUsageMetricsNavigator: {
                      type: "tab",
                      label: "Usage Metrics",
                      tabNavigator: {
                        position: "top",
                        showIcon: false,
                        showLabel: true,
                        upperCaseLabel: true,
                        scrollEnabled: true,
                        itemRenderer: checkRoutePermissions,
                        style: styles.childTabNavigator,
                        itemStyle: styles.childTabNavigatorItem,
                        activeItemStyle: styles.childTabNavigatorActiveItem,
                      },
                      initialRoute: "projectManualEntryUsageMetrics",
                      routes: {
                        projectManualEntryUsageMetrics: {
                          label: "Metrics",
                          path: "/:key/project/entry/usage/:uid/:manualEntryPipelineUid",
                          metadata: ({ route }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "manualEntryPipeline",
                            },
                          }),
                        },
                        projectManualEntryUsageMetricsHistory: {
                          label: "History",
                          path: "/:key/project/entry/usage/history/:uid/:manualEntryPipelineUid",
                          metadata: ({ route }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "manualEntryPipeline",
                            },
                          }),
                        },
                      },
                    },

                    projectManualEntryUsers: {
                      label: "Users",
                      path: "/:key/project/entry/users/:uid/:manualEntryPipelineUid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "manualEntryPipeline",
                        },
                      }),
                    },
                    projectManualEntryDocuments: {
                      label: "Documents",
                      path: "/:key/project/entry/documents/:uid/:manualEntryPipelineUid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "manualEntryConfig",
                        },
                      }),
                    },
                  },
                },
                projectVirtualWorkspaceNavigator: {
                  type: "tab",
                  //header: ResourceHeader,
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                  },
                  initialRoute: "projectVirtualWorkspace",
                  routes: {
                    projectVirtualWorkspace: {
                      label: "Dashboard",
                      path: "/:key/project/virtualWorkspace/:uid/:virtualWorkspaceUid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "virtualWorkspace",
                        },
                      }),
                    },
                    projectVirtualWorkspaceUserSessions: {
                      label: "User Sessions",
                      path: "/:key/project/virtualWorkspace/sessions/:uid/:virtualWorkspaceUid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "virtualWorkspaceUserSession",
                        },
                      }),
                    },
                  },
                },
                projectsNavigator: {
                  type: "tab",
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                  },
                  onWillBlur: (navigator, app) => {
                    app.dm.projects.clearInstances();
                  },
                  // metadata: ({ route }) => ({
                  //   permissions: {
                  //     type: route.params.key,
                  //   },
                  // }),
                  initialRoute: "projects",
                  routes: {
                    projects: {
                      label: "Projects",
                      path: `/:key/projects`,
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                        },
                      }),
                    },
                    projectsHealthChecks: {
                      label: "Health Checks",
                      path: `/:key/healthChecks`,
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "healthCheck",
                        },
                      }),
                    },
                    blocks: {
                      label: "Block Templates",
                      path: `/:key/blocks`,
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "block",
                        },
                      }),
                    },
                    datasets: {
                      label: "Datasets",
                      path: `/:key/datasets`,
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "dataset",
                        },
                      }),
                    },
                    projectsResources: {
                      path: `/:key/resources`,
                      label: "Resources",
                      icon: "cloud",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "resource",
                        },
                      }),
                    },
                  },
                },
                projectNavigator: {
                  type: "tab",
                  //header: ResourceHeader,
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                  },
                  initialRoute: "project",
                  routes: {
                    project: {
                      label: "Dashboard",
                      path: "/:key/project/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                        },
                      }),
                    },
                    projectDocumentTemplatesNavigator: {
                      type: "stack",
                      initialRoute: "projectDocumentTemplates",
                      label: "Document Templates",
                      metadata: ({ route }) => {
                        return {
                          permissions: {
                            type: route.params.key,
                            subtype: "documentTemplate",
                          },
                        };
                      },
                      // icon: "cloud",
                      routes: {
                        projectDocumentTemplates: {
                          path: `/:key/project/document/templates/:uid`,
                          label: "Document Templates",
                          icon: "documents",
                          metadata: ({ route, app }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "documentTemplate",
                            },
                            projectTypes: [app.dm.project.TYPE_IDP],
                          }),
                        },
                        projectDocumentTemplateNavigator: {
                          type: "stack",
                          initialRoute: "projectDocumentTemplate",
                          label: "Document Template",
                          // drawerNavigator: {
                          //   modalHeader: DrawerHeader,
                          //   position: "right",
                          //   showIcon: true,
                          //   showLabel: true,
                          //   upperCaseLabel: false,
                          //   scrollEnabled: true,
                          //   type: "modal",
                          //   //contentComponent: Drawer,
                          //   itemRenderer: checkRoutePermissions,
                          //   // itemStyle: styles.drawerItem,
                          //   // itemIconColor: Colors.primary,
                          //   // itemLabelTextStyle: styles.drawerItemLabelText,
                          //   // itemActiveStyle: styles.drawerItemActive,
                          //   // itemActiveIconColor: Colors.secondary,
                          //   // itemActiveLabelTextStyle: styles.drawerItemActiveLabelText,
                          // },
                          metadata: ({ route }) => {
                            return {
                              permissions: {
                                type: route.params.key,
                                subtype: "documentTemplate",
                              },
                            };
                          },
                          routes: {
                            projectDocumentTemplate: {
                              label: "Document Template",
                              path: "/:key/project/document/template/:uid/:documentTemplateUid",
                              metadata: ({ route }) => ({
                                permissions: {
                                  type: route.params.key,
                                  subtype: "documentTemplate",
                                },
                              }),
                            },
                          },
                        },
                      },
                    },
                    projectManualEntryNavigator: {
                      type: "stack",
                      initialRoute: "projectManualEntryPipelines",
                      label: "Manual Entry",
                      metadata: ({ route }) => {
                        return {
                          permissions: {
                            type: route.params.key,
                            subtype: "manualEntryPipeline",
                          },
                        };
                      },
                      // icon: "cloud",
                      routes: {
                        projectManualEntryConfigs: {
                          path: `/:key/project/configs/entry/:uid`,
                          // label: "Document Templates",
                          // icon: "documents",
                          metadata: ({ route, app }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "manualEntryConfig",
                            },
                            projectTypes: [app.dm.project.TYPE_IDP],
                          }),
                        },
                        projectManualEntryPipelines: {
                          path: `/:key/project/entry/:uid`,
                          // label: "Document Templates",
                          // icon: "documents",
                          metadata: ({ route, app }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "manualEntryPipeline",
                            },
                            projectTypes: [app.dm.project.TYPE_IDP],
                          }),
                        },
                      },
                    },
                    projectPipelinesNavigator: {
                      type: "stack",
                      initialRoute: "projectPipelines",
                      label: "Pipelines",
                      metadata: ({ route }) => {
                        return {
                          permissions: {
                            type: route.params.key,
                            subtype: "pipeline",
                          },
                        };
                      },
                      // projectCodeEditor: {
                      //   label: "File Explorer",
                      //   path: "/:key/project/editor/:uid",
                      //   metadata: ({ route }) => ({
                      //     permissions: {
                      //       type: route.params.key,
                      //       subtype: "codeEditor",
                      //     },
                      //   }),
                      // },
                      // icon: "cloud",
                      routes: {
                        projectPipelines: {
                          label: "Pipelines",
                          path: "/:key/project/pipelines/:uid",
                          metadata: ({ route }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "pipeline",
                            },
                          }),
                        },

                        projectPipelineNavigator: {
                          type: "stack",
                          initialRoute: "projectPipeline",
                          label: "Pipelines",
                          metadata: ({ route }) => {
                            return {
                              permissions: {
                                type: route.params.key,
                                subtype: "pipeline",
                              },
                            };
                          },
                          routes: {
                            projectPipeline: {
                              label: "Pipeline",
                              path: "/:key/project/pipeline/:uid/:pipelineUid",
                              metadata: ({ route }) => ({
                                permissions: {
                                  type: route.params.key,
                                  subtype: "pipeline",
                                },
                              }),
                            },
                            projectPipelineLog: {
                              label: "Pipeline",
                              path: "/:key/project/pipeline/log/:uid/:pipelineUid",
                              metadata: ({ route }) => ({
                                permissions: {
                                  type: route.params.key,
                                  subtype: "pipeline",
                                },
                              }),
                            },
                          },
                        },
                        projectPipelineBlock: {
                          label: "Pipeline Block",
                          path: "/:key/project/pipeline/block/:uid/:pipelineUid/:pipelineBlockUid",
                          metadata: ({ route }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "pipelineBlock",
                            },
                          }),
                        },
                      },
                    },
                    projectAppModules: {
                      label: "App Modules",
                      path: "/:key/project/modules/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "appModule",
                        },
                      }),
                    },
                    projectAppModuleBuilds: {
                      label: "App Builds",
                      path: "/:key/project/modules/builds/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "appModule",
                        },
                      }),
                    },
                    projectLogs: {
                      label: "Logs",
                      path: "/:key/project/logs/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "log",
                        },
                      }),
                    },
                    projectCodeEditor: {
                      label: "File Explorer",
                      path: "/:key/project/editor/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "codeEditor",
                        },
                      }),
                    },
                    projectResources: {
                      label: "Resources",
                      path: "/:key/project/resources/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "resource",
                        },
                      }),
                    },
                    projectHealthCheckNavigator: {
                      type: "stack",
                      initialRoute: "projectHealthChecks",
                      label: "Health Checks",
                      metadata: ({ route }) => {
                        return {
                          permissions: {
                            type: route.params.key,
                            subtype: "healthCheck",
                          },
                        };
                      },
                      // icon: "cloud",
                      routes: {
                        projectHealthChecks: {
                          path: "/:key/project/healthChecks/:uid",
                          metadata: ({ route }) => {
                            return {
                              permissions: {
                                type: route.params.key,
                                subtype: "healthCheck",
                              },
                            };
                          },
                        },
                        projectHealthCheck: {
                          path: "/:key/project/healthCheck/:uid/:projectHealthCheckUid",
                          metadata: ({ route }) => ({
                            permissions: {
                              type: route.params.key,
                              subtype: "healthCheck",
                            },
                          }),
                        },
                      },
                    },
                    projectDocs: {
                      label: "Documentation",
                      path: "/:key/project/docs/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "doc",
                        },
                      }),
                    },
                    projectBuilds: {
                      label: "Builds",
                      path: "/:key/project/builds/:uid",
                      metadata: ({ route }) => ({
                        permissions: {
                          type: route.params.key,
                          subtype: "build",
                        },
                      }),
                    },
                    //   projectVirtualWorkspaceUserSessions: {
                    //     label: "User Sessions",
                    //     path: "/:key/project/sessions/:uid",
                    //     metadata: ({ route }) => ({
                    //       permissions: {
                    //         type: route.params.key,
                    //         subtype: "virtualWorkspaceUserSession",
                    //       },
                    //     }),
                    //   },
                  },
                },
              },
            },
            cloudProjects: {
              to: {
                route: "projectsRootNavigator",
                params: {
                  key: "cloud",
                },
              },
              label: "Cloud Ops",
              icon: "cloud",
              metadata: {
                permissions: {
                  type: "cloud",
                },
              },
            },
            devProjects: {
              to: {
                route: "projectsRootNavigator",
                params: {
                  key: "dev",
                },
              },
              label: "Dev Studio",
              icon: "codeBraces",
              metadata: {
                permissions: {
                  type: "dev",
                },
              },
            },
            aiProjects: {
              to: {
                route: "projectsRootNavigator",
                params: {
                  key: "ai",
                },
              },
              label: "nQube",
              icon: "cube",
              metadata: {
                permissions: {
                  type: "ai",
                },
              },
            },
            idpProjects: {
              to: {
                route: "projectsRootNavigator",
                params: {
                  key: "idp",
                },
              },
              label: "IDP",
              icon: "documents",
              metadata: {
                permissions: {
                  type: "idp",
                },
              },
            },
            vwProjects: {
              to: {
                route: "projectsRootNavigator",
                params: {
                  key: "vw",
                },
              },
              label: "Virtual Workspaces",
              icon: "developerBoard",
              metadata: {
                permissions: {
                  type: "vw",
                },
              },
            },
            adminNavigator: {
              type: "tab",
              initialRoute: ({ app, navigator }) =>
                findInitialRoute("resources", navigator, app),
              // initialRoute: ({ navigator, app }) => {
              //   let ret = "resources";
              //   for (let routeName in navigator.routes) {
              //     if (
              //       checkRoutePermissions({
              //         route: navigator.routes[routeName],
              //         app,
              //       })
              //     ) {
              //       ret = routeName;
              //       break;
              //     }
              //   }
              //   return ret;
              // },
              label: "Admin",
              icon: "securityAccount",
              metadata: {
                permissions: {
                  type: "admin",
                },
              },
              tabNavigator: {
                position: "top",
                showIcon: false,
                showLabel: true,
                upperCaseLabel: true,
                scrollEnabled: true,
                itemRenderer: checkRoutePermissions,
              },
              routes: {
                users: {
                  path: `/users`,
                  label: "Users",
                  icon: "accountMultiple",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "user",
                    },
                  },
                },
                userPermissionGroups: {
                  path: `/user/permission/groups`,
                  label: "Permission Groups",
                  icon: "accountMultiple",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "userPermissionGroup",
                    },
                  },
                },
                adminManualEntryNavigator: {
                  type: "tab",
                  label: "Manual Entry",
                  icon: "securityAccount",
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                    style: styles.childTabNavigator,
                    itemStyle: styles.childTabNavigatorItem,
                    activeItemStyle: styles.childTabNavigatorActiveItem,
                  },
                  routes: {
                    manualEntryConfigs: {
                      path: `/manualEntry`,
                      label: "Configs",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "manualEntryConfig",
                        },
                      },
                    },
                    manualEntryRoutes: {
                      path: `/manualEntry/routes`,
                      label: "Routes",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "manualEntryRoute",
                        },
                      },
                    },
                    manualEntryPricingConfigs: {
                      path: `/manualEntry/pricing`,
                      label: "Pricing",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "manualEntryPricingConfig",
                        },
                      },
                    },
                    manualEntryUsers: {
                      path: `/manualEntry/users`,
                      label: "Users",
                      icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "manualEntryUser",
                        },
                      },
                    },

                    manualEntryUserBatchNavigator: {
                      label: "User Batches",
                      initialRoute: "manualEntryUserBatches",
                      type: "stack",
                      header: null,
                      routes: {
                        manualEntryUserBatches: {
                          path: `/manualEntry/users/batches`,
                          label: "User Batches",
                          metadata: {
                            permissions: {
                              type: "admin",
                              subtype: "manualEntryUser",
                            },
                          },
                        },
                        manualEntryUserBatch: {
                          path: `/manualEntry/users/batch/:uid`,
                          label: "User Batches",
                          metadata: {
                            permissions: {
                              type: "admin",
                              subtype: "manualEntryUser",
                            },
                          },
                        },
                      },
                    },
                    manualEntryErrors: {
                      path: `/manualEntry/errors`,
                      label: "Errors",
                      // icon: "accountMultiple",q
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "manualEntryError",
                        },
                      },
                    },
                    manualEntryUserPaymentBatchNavigator: {
                      label: "Payment Batches",
                      initialRoute: "manualEntryUserPaymentBatches",
                      type: "stack",
                      header: null,
                      routes: {
                        manualEntryUserPaymentBatches: {
                          path: `/manualEntry/payment/batches`,
                          label: "Payment Batches",
                          metadata: {
                            permissions: {
                              type: "admin",
                              subtype: "manualEntryUserPaymentBatch",
                            },
                          },
                        },
                        manualEntryUserPaymentBatch: {
                          path: `/manualEntry/payment/batches/:uid`,
                          // label: "Payment Batch",
                          metadata: {
                            permissions: {
                              type: "admin",
                              subtype: "manualEntryUserPaymentBatch",
                            },
                          },
                        },
                      },
                    },

                    manualEntryAccuracyTests: {
                      path: `/manualEntry/AccuracyTests`,
                      label: "Accuracy Tests",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "manualEntryAccuracyTest",
                        },
                      },
                    },
                  },
                },
                adminVirtualWorkspaceNavigator: {
                  type: "tab",
                  initialRoute: ({ app, navigator }) =>
                    findInitialRoute("virtualWorkspaceImages", navigator, app),
                  label: "Virtual Workspaces",
                  icon: "securityAccount",
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                    style: styles.childTabNavigator,
                    itemStyle: styles.childTabNavigatorItem,
                    activeItemStyle: styles.childTabNavigatorActiveItem,
                  },
                  routes: {
                    virtualWorkspaceConfigs: {
                      path: `/virtualWorkspace/configs`,
                      label: "Configs",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "virtualWorkspaceConfig",
                        },
                      },
                    },
                    virtualWorkspaces: {
                      path: `/virtualWorkspace/workspaces`,
                      label: "Workspaces",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "virtualWorkspace",
                        },
                      },
                    },
                    virtualWorkspaceImages: {
                      path: `/virtualWorkspace/images`,
                      label: "Images",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "virtualWorkspaceImage",
                        },
                      },
                    },
                    virtualWorkspaceRegions: {
                      path: `/virtualWorkspace/regions`,
                      label: "Regions",
                      // icon: "accountMultiple",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "virtualWorkspaceRegion",
                        },
                      },
                    },
                  },
                },
                activityLog: {
                  path: `/activity`,
                  label: "Activity Log",
                  icon: "console",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "activityLog",
                    },
                  },
                },
                resources: {
                  path: `/resources`,
                  label: "Resources",
                  icon: "cloud",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "resource",
                    },
                  },
                },
                cloudAccessKeys: {
                  path: `/cloudAccessKeys`,
                  label: "Cloud Access Keys",
                  icon: "cloudSync",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "cloudAccessKey",
                    },
                  },
                },
                sshKeys: {
                  path: `/sshKeys`,
                  label: "SSH Keys",
                  icon: "consoleNetwork",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "sshKey",
                    },
                  },
                },
                events: {
                  path: `/events`,
                  label: "Events",
                  icon: "calendarClock",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "event",
                    },
                  },
                  //action: 'events',
                },
                scripts: {
                  path: `/scripts`,
                  label: "Scripts",
                  icon: "codeBraces",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "script",
                    },
                  },
                },
                logs: {
                  path: `/logs`,
                  label: "Logs",
                  icon: "console",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "log",
                    },
                  },
                },
                contactGroups: {
                  path: `/contactGroups`,
                  label: "Contact Groups",
                  icon: "notificationsNone",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "contactGroup",
                    },
                  },
                },
                statusChecks: {
                  path: `/statusChecks`,
                  label: "Status Checks",
                  icon: "heartPulse",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "statusCheck",
                    },
                  },
                },
                codeRepos: {
                  path: `/codeRepos`,
                  label: "Code Repos",
                  icon: "codeBraces",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "codeRepo",
                    },
                  },
                },
                clients: {
                  path: `/clients`,
                  label: "Clients",
                  metadata: {
                    permissions: {
                      type: "admin",
                      subtype: "client",
                    },
                  },
                },
                adminR14Navigator: {
                  type: "tab",
                  label: "Rule 14",
                  icon: "securityAccount",
                  tabNavigator: {
                    position: "top",
                    showIcon: false,
                    showLabel: true,
                    upperCaseLabel: true,
                    scrollEnabled: true,
                    itemRenderer: checkRoutePermissions,
                    style: styles.childTabNavigator,
                    itemStyle: styles.childTabNavigatorItem,
                    activeItemStyle: styles.childTabNavigatorActiveItem,
                  },
                  routes: {
                    r14Licenses: {
                      path: `/rule14/licenses`,
                      label: "Licenses",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "r14Licenses",
                        },
                      },
                    },
                    r14Deployments: {
                      path: `/rule14/deployments`,
                      label: "Deployments",
                      metadata: {
                        permissions: {
                          type: "admin",
                          subtype: "r14Deployments",
                        },
                      },
                    },
                  },
                },
              },
            },
            support: {
              path: `/support`,
              label: "Support",
              icon: "help",
              //action: 'events',
            },
            // events: {
            //   path: `/events`,
            //   label: "Events",
            //   icon: "calendarClock"
            //   //action: 'events',
            // },
            // scripts: {
            //   path: `/scripts`,
            //   label: "Scripts",
            //   icon: "codeBraces"
            // },
            // logs: {
            //   path: `/logs`,
            //   label: "Logs",
            //   icon: "console"
            // },
            // alerts: {
            //   path: `/alerts`,
            //   label: "Alerts",
            //   icon: "notificationsNone"
            // },
            // statusChecks: {
            //   path: `/statusChecks`,
            //   label: "Status Checks",
            //   icon: "heartPulse"
            // },
            // users: {
            //   path: `/users`,
            //   label: "Users",
            //   icon: "accountMultiple"
            // },
            accountSettings: {
              path: `/account/settings`,
              visible: false,
            },
            test: {
              path: `/test`,
              visible: false,
            },
            themes: {
              path: `/themes`,
              visible: false,
            },
            timesheet: {
              path: `/timesheet`,
              visible: false,
            },
            // clients: {
            //   path: `/clients`,
            //   visible: false,
            //   metadata: {
            //     permissions: {
            //       type: "admin",
            //       subtype: "client"
            //     }
            //   }
            // }
            // cloudAccessKeys: {
            //   path: `/cloudAccessKeys`,
            //   label: "Cloud Access Keys",
            //   icon: "cloudSync"
            // },
            // sshKeys: {
            //   path: `/sshKeys`,
            //   label: "SSH Keys",
            //   icon: "consoleNetwork"
            // }
          },
        },
      },
    },
    appModuleCreateContainer: {
      type: "container",
      initialRoute: "appModuleCreate",
      routes: {
        appModuleCreate: {
          title: "App Module Create",
          path: "/:key/module/create",
        },
      },
    },
    modal: {
      type: "modal",
      initialRoute: "projectCreate",
      modal: {},
      header: {},
      routes: {
        resourceEdit: {
          title: "Edit Resource",
          path: "/resource/edit/:uid",
          initialParentRoute: "resources",
          metadata: {
            permissions: {
              type: "resource",
              access: "edit",
            },
          },
        },
        eventCreate: {
          title: "Create Event",
          path: "/event/create",
          initialParentRoute: "events",
          action: "eventEdit",
          metadata: {
            permissions: {
              type: "event",
              access: "create",
            },
          },
        },
        eventEdit: {
          title: "Edit Event",
          path: "/event/edit/:uid",
          initialParentRoute: "events",
          metadata: {
            permissions: {
              type: "event",
              access: "edit",
            },
          },
        },
        scriptCreate: {
          title: "Create Script",
          path: "/script/create",
          initialParentRoute: "scripts",
          action: "scriptEdit",
          metadata: {
            permissions: {
              type: "script",
              access: "create",
            },
          },
        },
        scriptEdit: {
          title: "Edit Script",
          path: "/script/edit/:uid",
          initialParentRoute: "scripts",
          metadata: {
            permissions: {
              type: "script",
              access: "edit",
            },
          },
        },
        resourceScriptAdd: {
          title: "Add Script",
          path: "/resource/script/add/:uid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
              access: "create",
            },
          },
        },
        resourceScriptCreate: {
          title: "Create Script",
          path: "/resource/script/create/:uid",
          initialParentRoute: "resource",
          action: "resourceScriptEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
              access: "create",
            },
          },
        },
        resourceScriptEdit: {
          title: "Edit Script",
          path: "/resource/script/edit/:uid/:scriptUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
              access: "edit",
            },
          },
        },
        resourceScriptLog: {
          title: "Script Log",
          path: "/resource/script/log/:uid/:resourceScriptUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
            },
          },
        },
        resourceScriptsAdd: {
          title: "Add Script",
          path: "/resource/scripts/add/:uid",
          initialParentRoute: "resourceScripts",
          action: "resourceScriptAdd",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
              access: "create",
            },
          },
        },
        resourceScriptsEdit: {
          title: "Edit Script",
          path: "/resource/scripts/edit/:uid/:scriptUid",
          initialParentRoute: "resourceScripts",
          action: "resourceScriptEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
              access: "edit",
            },
          },
        },
        resourceScriptsCreate: {
          title: "Create Script",
          path: "/resource/scripts/create/:uid",
          initialParentRoute: "resourceScripts",
          action: "resourceScriptEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "script",
              access: "create",
            },
          },
        },
        resourceEventTaskAdd: {
          title: "Add Event",
          path: "/resource/event/add/:uid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "event",
              access: "create",
            },
          },
        },
        resourceEventTaskEdit: {
          title: "Edit Event",
          path: "/resource/event/edit/:uid/:eventUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "event",
              access: "edit",
            },
          },
        },
        resourceEventTaskCreate: {
          title: "Create Event",
          path: "/resource/event/create/:uid",
          initialParentRoute: "resource",
          action: "resourceEventTaskEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "event",
              access: "create",
            },
          },
        },
        resourceEventTasksAdd: {
          title: "Add Event",
          path: "/resource/events/add/:uid",
          initialParentRoute: "resourceEventTasks",
          action: "resourceEventTaskAdd",
        },
        resourceEventTasksEdit: {
          title: "Edit Event",
          path: "/resource/events/edit/:uid/:eventUid",
          initialParentRoute: "resourceEventTasks",
          action: "resourceEventTaskEdit",
        },
        resourceEventTasksCreate: {
          title: "Create Event",
          path: "/resource/events/create/:uid",
          initialParentRoute: "resourceEventTasks",
          action: "resourceEventTaskEdit",
        },
        logCreate: {
          title: "Create Log",
          path: "/log/create",
          initialParentRoute: "logs",
          action: "logEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "log",
              access: "create",
            },
          },
        },
        logEdit: {
          title: "Edit Log",
          path: "/log/edit/:uid",
          initialParentRoute: "logs",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "log",
              access: "edit",
            },
          },
        },
        resourceLogAdd: {
          title: "Add Log",
          path: "/resource/log/add/:uid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
              access: "create",
            },
          },
        },
        resourceLogCreate: {
          title: "Create Log",
          path: "/resource/log/create/:uid",
          initialParentRoute: "resource",
          action: "resourceLogEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
              access: "create",
            },
          },
        },
        resourceLogEdit: {
          title: "Edit Log",
          path: "/resource/log/edit/:uid/:logUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
              access: "edit",
            },
          },
        },
        resourceLog: {
          title: "Log",
          path: "/resource/log/:uid/:logUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
            },
          },
        },
        resourceLogsAdd: {
          title: "Add Log",
          path: "/resource/logs/add/:uid",
          initialParentRoute: "resourceLogs",
          action: "resourceLogAdd",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
              access: "create",
            },
          },
        },
        resourceLogsEdit: {
          title: "Edit Log",
          path: "/resource/logs/edit/:uid/:scriptUid",
          initialParentRoute: "resourceLogs",
          action: "resourceLogEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
              access: "edit",
            },
          },
        },
        resourceLogsCreate: {
          title: "Create Log",
          path: "/resource/logs/create/:uid",
          initialParentRoute: "resourceLogs",
          action: "resourceLogEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "log",
              access: "create",
            },
          },
        },
        userCreate: {
          title: "Create User",
          path: "/user/create",
          initialParentRoute: "users",
          action: "userEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "user",
              access: "create",
            },
          },
        },
        userEdit: {
          title: "Edit User",
          path: "/user/edit/:uid",
          initialParentRoute: "users",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "user",
              access: "edit",
            },
          },
        },
        userPermissionsEdit: {
          title: "Edit User Permissions",
          path: "/user/permissions/edit/:uid",
          initialParentRoute: "users",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "userPermission",
              access: "edit",
            },
          },
        },
        userPermissionGroupCreate: {
          title: "Create Permissions",
          path: "/user/permission/group/create",
          initialParentRoute: "userPermissionGroups",
          action: "userPermissionGroupEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "userPermissionGroup",
              access: "create",
            },
          },
        },
        userPermissionGroupEdit: {
          title: "Edit Permissions",
          path: "/user/permission/group/edit/:uid",
          initialParentRoute: "userPermissionGroups",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "userPermissionGroup",
              access: "edit",
            },
          },
        },
        manualEntryConfigCreate: {
          title: "Create Manual Entry Config",
          path: "/manualEntry/create",
          initialParentRoute: "manualEntryConfigs",
          action: "manualEntryConfigEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryConfig",
              access: "create",
            },
          },
        },
        manualEntryConfigEdit: {
          title: "Edit Manual Entry Config",
          path: "/manualEntry/edit/:uid",
          initialParentRoute: "manualEntryConfigs",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryConfig",
              access: "edit",
            },
          },
        },
        manualEntryRouteCreate: {
          title: "Create Manual Entry Route",
          path: "/manualEntry/route/create",
          initialParentRoute: "manualEntryRoutes",
          action: "manualEntryRouteEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryRoute",
              access: "create",
            },
          },
        },
        manualEntryRouteEdit: {
          title: "Edit Manual Entry Route",
          path: "/manualEntry/route/edit/:uid",
          initialParentRoute: "manualEntryRoutes",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryRoute",
              access: "edit",
            },
          },
        },
        manualEntryUserPaymentBatchCreate: {
          title: "Create Payment Batch",
          path: "/manualEntry/payment/batches/create",
          initialParentRoute: "manualEntryPaymentBatches",
          action: "manualEntryUserPaymentBatchEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryPaymentBatch",
              access: "create",
            },
          },
        },
        manualEntryUserPaymentBatchEdit: {
          title: "Edit Payment Batch",
          path: "/manualEntry/payment/batches/edit/:uid",
          initialParentRoute: "manualEntryPaymentBatches",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryPaymentBatch",
              access: "edit",
            },
          },
        },
        manualEntryPricingConfigCreate: {
          title: "Create Manual Entry Pricing",
          path: "/manualEntry/pricing/create",
          initialParentRoute: "manualEntryPricingConfig",
          action: "manualEntryPricingConfigEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryPricingConfig",
              access: "create",
            },
          },
          dialogStyle: styles.manualEntryPricingConfigEditDialog,
        },
        manualEntryPricingConfigEdit: {
          title: "Edit Manual Entry Pricing",
          path: "/manualEntry/pricing/edit/:uid",
          initialParentRoute: "manualEntryPricingConfig",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryPricingConfig",
              access: "edit",
            },
          },
          dialogStyle: styles.manualEntryPricingConfigEditDialog,
        },
        manualEntryAccuracyTestCreate: {
          title: "Create Accuracy Test",
          path: "/manualEntryAccuracyTests/create",
          initialParentRoute: "manualEntryAccuracyTests",
          action: "manualEntryAccuracyTestEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryAccuracyTest",
              access: "create",
            },
          },
        },
        manualEntryAccuracyTestEdit: {
          title: "Edit Accuracy Test",
          path: "/manualEntryAccuracyTests/edit/:uid",
          initialParentRoute: "manualEntryAccuracyTests",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryAccuracyTest",
              access: "edit",
            },
          },
        },
        virtualWorkspaceConfigCreate: {
          title: "Create Virtual Workspace Config",
          path: "/virtualWorkspace/config/create",
          initialParentRoute: "virtualWorkspaceConfigs",
          action: "virtualWorkspaceConfigEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspaceConfig",
              access: "create",
            },
          },
        },
        virtualWorkspaceConfigEdit: {
          title: "Edit Virtual Workspace Config",
          path: "/virtualWorkspace/config/edit/:uid",
          initialParentRoute: "virtualWorkspaceConfigs",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspaceConfig",
              access: "edit",
            },
          },
        },
        virtualWorkspaceCreate: {
          title: "Create Virtual Workspace",
          path: "/virtualWorkspace/workspace/create",
          initialParentRoute: "virtualWorkspaces",
          action: "virtualWorkspaceEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspace",
              access: "create",
            },
          },
        },
        virtualWorkspaceEdit: {
          title: "Edit Virtual Workspace",
          path: "/virtualWorkspace/workspace/edit/:uid",
          initialParentRoute: "virtualWorkspaces",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspace",
              access: "edit",
            },
          },
        },
        virtualWorkspaceImageCreate: {
          title: "Create Virtual Workspace Image",
          path: "/virtualWorkspace/image/create",
          initialParentRoute: "virtualWorkspaceImages",
          action: "virtualWorkspaceImageEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspaceImage",
              access: "create",
            },
          },
        },
        virtualWorkspaceImageEdit: {
          title: "Edit Virtual Workspace Image",
          path: "/virtualWorkspace/images/edit/:uid",
          initialParentRoute: "virtualWorkspaceImages",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspaceImage",
              access: "edit",
            },
          },
        },
        virtualWorkspaceRegionCreate: {
          title: "Create Virtual Workspace Region",
          path: "/virtualWorkspace/region/create",
          initialParentRoute: "virtualWorkspaceRegions",
          action: "virtualWorkspaceRegionEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspaceRegion",
              access: "create",
            },
          },
        },
        virtualWorkspaceRegionEdit: {
          title: "Edit Virtual Workspace Region",
          path: "/virtualWorkspace/region/edit/:uid",
          initialParentRoute: "virtualWorkspaceRegions",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "virtualWorkspaceRegion",
              access: "edit",
            },
          },
        },
        projectManualEntryPipelineCreate: {
          title: "Create Manual Entry Project",
          path: "/:key/project/entry/create/:uid",
          initialParentRoute: "projectManualEntryPipelines",
          action: "projectManualEntryPipelineEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "manualEntryPipeline",
              access: "create",
            },
          }),
        },
        projectManualEntryPipelineEdit: {
          title: "Edit Manual Entry Project",
          path: "/:key/project/entry/edit/:uid/:manualEntryPipelineUid",
          initialParentRoute: "projectManualEntryPipelines",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "manualEntryPipeline",
              access: "edit",
            },
          }),
        },
        manualEntryUserCreate: {
          title: "Create Manual Entry User",
          path: "/manualEntryUser/create",
          initialParentRoute: "manualEntryUsers",
          action: "manualEntryUserEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryUser",
              access: "create",
            },
          },
        },
        manualEntryUserEdit: {
          title: "Edit Manual Entry User",
          path: "/manualEntryUser/edit/:uid",
          initialParentRoute: "manualEntryUsers",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryUser",
              access: "edit",
            },
          },
        },
        manualEntryUserBatchCreate: {
          title: "Create Manual Entry User Batch",
          path: "/manualEntryUser/batch/create",
          initialParentRoute: "manualEntryUserBatches",
          action: "manualEntryUserBatchEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryUser",
              access: "create",
            },
          },
        },
        manualEntryUserBatchEdit: {
          title: "Edit Manual Entry User Batch",
          path: "/manualEntryUser/batch/edit/:uid",
          initialParentRoute: "manualEntryUserBatches",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "manualEntryUser",
              access: "edit",
            },
          },
        },
        clientCreate: {
          title: "Create Client",
          path: "/client/create",
          initialParentRoute: "clients",
          action: "clientEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "client",
              access: "create",
            },
          },
        },
        clientEdit: {
          title: "Edit Client",
          path: "/client/edit/:uid",
          initialParentRoute: "clients",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "client",
              access: "edit",
            },
          },
        },
        r14LicenseCreate: {
          title: "Create License",
          path: "/rule14/license/create",
          initialParentRoute: "r14Licenses",
          action: "r14LicenseEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "r14License",
              access: "create",
            },
          },
        },
        r14LicenseEdit: {
          title: "Edit License",
          path: "/rule14/license/edit/:uid",
          initialParentRoute: "r14Licenses",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "r14License",
              access: "edit",
            },
          },
        },
        r14DeploymentCreate: {
          title: "Create Deployment",
          path: "/rule14/deployment/create",
          initialParentRoute: "r14Deployments",
          action: "r14DeploymentEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "r14Deployment",
              access: "create",
            },
          },
        },
        r14DeploymentEdit: {
          title: "Edit Deployment",
          path: "/rule14/deployment/edit/:uid",
          initialParentRoute: "r14Deployments",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "r14Deployment",
              access: "edit",
            },
          },
        },
        statusCheckCreate: {
          title: "Create Status Check",
          path: "/status/create",
          initialParentRoute: "statusChecks",
          action: "statusCheckEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "statusCheck",
              access: "create",
            },
          },
        },
        statusCheckEdit: {
          title: "Edit Status Check",
          path: "/status/edit/:uid",
          initialParentRoute: "statusChecks",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "statusCheck",
              access: "edit",
            },
          },
        },
        resourceStatusCheckAdd: {
          title: "Add Status Check",
          path: "/resource/status/add/:uid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
              access: "create",
            },
          },
        },
        resourceStatusCheckLog: {
          title: "Status Check Log",
          path: "/resource/status/log/:uid/:resourceStatusCheckUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
            },
          },
        },
        resourceStatusCheckEdit: {
          title: "Edit Status Check",
          path: "/resource/status/edit/:uid/:statusCheckUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
              access: "edit",
            },
          },
        },
        resourceStatusCheckCreate: {
          title: "Create Status check",
          path: "/resource/status/create/:uid",
          initialParentRoute: "resource",
          action: "resourceStatusCheckEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
              access: "create",
            },
          },
        },
        resourceStatusChecksAdd: {
          title: "Add Status Check",
          path: "/resource/statusChecks/add/:uid",
          initialParentRoute: "resourceStatusChecks",
          action: "resourceStatusCheckAdd",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
              access: "create",
            },
          },
        },
        resourceStatusChecksEdit: {
          title: "Edit Status Check",
          path: "/resource/statusChecks/edit/:uid/:statusCheckUid",
          initialParentRoute: "resourceStatusChecks",
          action: "resourceStatusCheckEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
              access: "edit",
            },
          },
        },
        resourceStatusChecksCreate: {
          title: "Create Status Check",
          path: "/resource/statusChecks/create/:uid",
          initialParentRoute: "resourceStatusChecks",
          action: "resourceStatusCheckEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "statusCheck",
              access: "create",
            },
          },
        },
        contactGroupEdit: {
          title: "Edit Contact Group",
          path: "/contactGroup/edit/:uid",
          initialParentRoute: "contactGroups",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "contactGroup",
              access: "edit",
            },
          },
        },
        contactGroupCreate: {
          title: "Create Contact Group",
          path: "/contactGroup/create",
          initialParentRoute: "contactGroups",
          action: "contactGroupEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "contactGroup",
              access: "create",
            },
          },
        },
        // alertEdit: {
        //   title: "Edit Contact Group",
        //   path: "/contactGroup/edit/:uid",
        //   initialParentRoute: "contactGroups",
        //   metadata: {
        //     permissions: {
        //       type: "admin",
        //       subtype: "contactGroup",
        //       access: "edit",
        //     },
        //   },
        // },
        // resourceAlertAdd: {
        //   title: "Add Alert",
        //   path: "/resource/alert/add/:uid",
        //   initialParentRoute: "resource",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //       access: "create",
        //     },
        //   },
        // },
        // resourceAlertEdit: {
        //   title: "Edit Alert",
        //   path: "/resource/alert/edit/:uid/:alertUid",
        //   initialParentRoute: "resource",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //       access: "edit",
        //     },
        //   },
        // },
        // resourceAlertCreate: {
        //   title: "Create Alert",
        //   path: "/resource/alert/create/:uid",
        //   initialParentRoute: "resource",
        //   action: "resourceAlertEdit",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //       access: "create",
        //     },
        //   },
        // },
        // resourceAlertLog: {
        //   title: "Alert Log",
        //   path: "/resource/alert/log/:uid/:resourceAlertUid",
        //   initialParentRoute: "resource",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //     },
        //   },
        // },
        // resourceAlertsAdd: {
        //   title: "Add Alert",
        //   path: "/resource/alerts/add/:uid",
        //   initialParentRoute: "resourceAlerts",
        //   action: "resourceAlertAdd",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //       access: "create",
        //     },
        //   },
        // },
        // resourceAlertsEdit: {
        //   title: "Edit Alert",
        //   path: "/resource/alerts/edit/:uid/:scriptUid",
        //   initialParentRoute: "resourceAlerts",
        //   action: "resourceAlertEdit",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //       access: "edit",
        //     },
        //   },
        // },
        // resourceAlertsCreate: {
        //   title: "Create Alert",
        //   path: "/resource/alerts/create/:uid",
        //   initialParentRoute: "resourceAlerts",
        //   action: "resourceAlertEdit",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "alert",
        //       access: "create",
        //     },
        //   },
        // },
        cloudAccessKeyCreate: {
          title: "Create Access Key",
          path: "/cloudAccessKey/create",
          initialParentRoute: "cloudAccessKeys",
          action: "cloudAccessKeyEdit",
          metadata: {
            permissions: {
              type: "cloudAccessKey",
              access: "create",
            },
          },
        },
        cloudAccessKeyEdit: {
          title: "Edit Access Keys",
          path: "/cloudAccessKey/edit/:uid",
          initialParentRoute: "cloudAccessKeys",
          metadata: {
            permissions: {
              type: "cloudAccessKey",
              access: "edit",
            },
          },
        },
        // cloudAccessKeyResourcesImport: {
        //   title: "Import Resources",
        //   path: "/cloudAccessKey/resources/import/:uid",
        //   initialParentRoute: "cloudAccessKeys",
        //   metadata: {
        //     permissions: {
        //       type: "resource",
        //       subtype: "import"
        //     }
        //   }
        // },
        sshKeyCreate: {
          title: "Create SSH Key",
          path: "/sshKey/create",
          initialParentRoute: "sshKeys",
          action: "sshKeyEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "sshKey",
              access: "create",
            },
          },
        },
        sshKeyEdit: {
          title: "Edit SSH Keys",
          path: "/sshKey/edit/:uid",
          initialParentRoute: "sshKeys",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "sshKey",
              access: "edit",
            },
          },
        },
        resourceSshKeyAdd: {
          title: "Add SSH Key",
          path: "/resource/sshKey/add/:uid",
          initialParentRoute: "resource",
          action: "resourceSshKeyManage",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "sshKey",
              access: "create",
            },
          },
        },
        resourceSshKeyManage: {
          title: "Manage SSH Key",
          path: "/resource/sshKey/manage/:uid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "sshKey",
            },
          },
        },
        resourceSshKeyCreate: {
          title: "Create SSH Key",
          path: "/resource/sshKey/create/:uid",
          initialParentRoute: "resource",
          action: "resourceSshKeyEdit",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "sshKey",
              access: "create",
            },
          },
        },
        resourceSshKeyEdit: {
          title: "Edit SSH Key",
          path: "/resource/sshKey/edit/:uid/:sshKeyUid",
          initialParentRoute: "resource",
          metadata: {
            permissions: {
              type: "resource",
              subtype: "sshKey",
              access: "edit",
            },
          },
        },
        projectResourceAdd: {
          title: "Add Resource",
          path: "/:key/project/resource/add/:uid",
          initialParentRoute: "projectResources",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "resource",
              access: "create",
            },
          }),
        },
        themeCreate: {
          title: "Create Theme",
          path: "/themes/create",
          initialParentRoute: "themes",
          action: "themeEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "theme",
              access: "create",
            },
          },
        },
        themeEdit: {
          title: "Edit Theme",
          path: "/themes/edit/:uid",
          initialParentRoute: "themes",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "theme",
              access: "edit",
            },
          },
        },
        timesheetCreate: {
          title: "Add Item",
          path: "/timesheet/create",
          initialParentRoute: "timesheet",
          action: "timesheetEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "timesheet",
              access: "create",
            },
          },
        },
        timesheetEdit: {
          title: "Edit Item",
          path: "/timesheet/edit/:uid",
          initialParentRoute: "timesheet",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "timesheet",
              access: "edit",
            },
          },
        },
        todo: {
          title: "To Do",
          path: "/todos/board/:uid",
          initialParentRoute: "todosBoard",
          metadata: {
            permissions: {
              type: "todo",
            },
          },
        },
        todoCreate: {
          title: "Create Todo",
          path: "/todo/create",
          initialParentRoute: "todosBoard",
          action: "todoEdit",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "todo",
              access: "create",
            },
          },
        },
        todoEdit: {
          title: "Edit Todo",
          path: "/todo/edit/:uid",
          initialParentRoute: "todosBoard",
          metadata: {
            permissions: {
              type: "admin",
              subtype: "todo",
              access: "edit",
            },
          },
        },
        projectCreate: {
          // title: "Create Project",
          path: "/:key/project/create/",
          initialParentRoute: "projects",
          action: "projectEdit",
          options: ({ route, app }) => ({
            title: `Create ${app.dm.project.getTypeLabelByKey(
              route.params.key
            )} Project`,
          }),
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              //subtype: "project",
              access: "create",
            },
          }),
        },
        projectEdit: {
          title: "Edit Project",
          path: "/:key/project/edit/:uid",
          initialParentRoute: "projects",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              //subtype: "project",
              access: "edit",
            },
          }),
        },
        rootProjectCreate: {
          title: "Create Project",
          path: "/project/create/",
          initialParentRoute: "rootProjects",
          action: "projectEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: "project",
              access: "create",
            },
          }),
        },
        rootProjectEdit: {
          title: "Edit Project",
          path: "/project/edit/:uid",
          initialParentRoute: "rootProjects",
          action: "projectEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: "project",
              access: "edit",
            },
          }),
        },
        // appModuleCreate: {
        //   title: "Create App Module",
        //   path: "/module/create",
        //   initialParentRoute: "appModules",
        //   action: "appModuleEdit",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule",
        //       access: "create"
        //     }
        //   }
        // },
        // appModuleEdit: {
        //   title: "Edit App Module",
        //   path: "/module/edit/:uid",
        //   initialParentRoute: "appModules",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule",
        //       access: "create"
        //     }
        //   }
        // },
        projectAppModuleCreate: {
          title: "Create App Module",
          // path: "/module/create",
          path: "/:key/project/module/create/:uid",
          initialParentRoute: "projectAppModules",
          action: "projectAppModuleEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "create",
            },
          }),
        },
        projectAppModuleCopy: {
          title: "Create App Module",
          // path: "/module/create",
          path: "/:key/project/module/copy/:uid/:copyAppModuleUid",
          initialParentRoute: "projectAppModules",
          action: "projectAppModuleEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "create",
            },
          }),
        },
        projectAppModuleEdit: {
          title: "Edit App Module",
          path: "/:key/project/module/edit/:uid/:appModuleUid",
          initialParentRoute: "projectAppModules",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectAppModuleBuildEdit: {
          title: "Edit App Build",
          path: "/:key/project/module/build/edit/:uid/:appModuleBuildUid",
          initialParentRoute: "projectAppModules",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectAppModuleBuildCreate: {
          title: "Create App Build",
          // path: "/module/create",
          path: "/:key/project/module/build/create/:uid",
          initialParentRoute: "projectAppModuleBuilds",
          action: "projectAppModuleBuildEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "create",
            },
          }),
        },
        projectAppModuleLog: {
          title: "App Module Log",
          path: "/:key/project/module/log/:uid/:appModuleUid",
          initialParentRoute: "project",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
            },
          }),
        },
        projectAppModuleIdeLog: {
          title: "App Module Log",
          path: "/:key/project/module/ide/log/:uid/:appModuleUid",
          initialParentRoute: "projectAppModuleIde",
          action: "projectAppModuleLog",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
            },
          }),
        },
        projectPipelineBlockIdeLog: {
          title: "App Module Log",
          path: "/:key/project/pipeline/block/ide/log/:uid/:pipelineUid/:pipelineBlockUid/:appModuleUid",
          initialParentRoute: "projectPipelineBlockIde",
          action: "projectAppModuleLog",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
            },
          }),
        },
        projectAppModuleServerLog: {
          title: "App Module Log",
          path: "/:key/project/module/log/:serverType/:uid/:appModuleUid",
          initialParentRoute: "project",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
            },
          }),
        },
        projectAppModuleMonitor: {
          title: "App Module Monitor",
          path: "/:key/project/module/monitor/:serverType/:uid/:appModuleUid",
          initialParentRoute: "project",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
            },
          }),
        },
        projectAppModuleManagePackages: {
          title: "Manage Packages",
          path: "/:key/project/module/packages/:uid/:appModuleUid",
          initialParentRoute: "project",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectAppModuleIdeManagePackages: {
          title: "Manage Packages",
          path: "/:key/project/module/ide/packages/:uid/:appModuleUid",
          initialParentRoute: "projectAppModuleIde",
          action: "projectAppModuleManagePackages",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectPipelineBlockIdeManagePackages: {
          title: "Manage Packages",
          path: "/:key/project/pipeline/block/ide/packages/:uid/:pipelineUid/:pipelineBlockUid/:appModuleUid",
          initialParentRoute: "projectPipelineBlockIde",
          action: "projectAppModuleManagePackages",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectAppModuleManageRepos: {
          title: "Manage Repositories",
          path: "/:key/project/module/repos/:uid/:appModuleUid",
          initialParentRoute: "project",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectAppModuleIdeManageRepos: {
          title: "Manage Repositories",
          path: "/:key/project/module/ide/repos/:uid/:appModuleUid",
          initialParentRoute: "projectAppModuleIde",
          action: "projectAppModuleManageRepos",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },
        projectPipelineBlockIdeManageRepos: {
          title: "Manage Repositories",
          path: "/:key/project/pipeline/block/ide/repos/:uid/:pipelineUid/:pipelineBlockUid/:appModuleUid",
          initialParentRoute: "projectPipelineBlockIde",
          action: "projectAppModuleManageRepos",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "appModule",
              access: "edit",
            },
          }),
        },

        projectHealthCheckEdit: {
          title: "Edit Health Check",
          path: "/:key/project/healthCheck/edit/:uid/:projectHealthCheckUid",
          initialParentRoute: "project",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "healthCheck",
              access: "edit",
            },
          }),
        },
        projectHealthCheckCreate: {
          title: "Create Health Check",
          path: "/:key/project/healthCheck/create/:uid",
          initialParentRoute: "project",
          action: "projectHealthCheckEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "healthCheck",
              access: "create",
            },
          }),
        },
        blockEdit: {
          title: "Edit Block Template",
          path: "/:key/block/edit/:uid",
          initialParentRoute: "blocks",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "block",
              access: "edit",
            },
          }),
        },
        blockCreate: {
          title: "Create Block Template",
          path: "/:key/block/create",
          initialParentRoute: "blocks",
          action: "blockEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "block",
              access: "create",
            },
          }),
        },
        blockCopy: {
          title: "Block Template Copy",
          path: "/:key/block/copy/:uid",
          initialParentRoute: "blocks",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "block",
              access: "create",
            },
          }),
        },
        datasetEdit: {
          title: "Edit Dataset",
          path: "/:key/dataset/edit/:uid",
          initialParentRoute: "datasets",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "dataset",
              access: "edit",
            },
          }),
        },
        datasetCreate: {
          title: "Create Dataset",
          path: "/:key/dataset/create",
          initialParentRoute: "datasets",
          action: "datasetEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "dataset",
              access: "create",
            },
          }),
        },
        projectDocumentTemplateEdit: {
          title: "Edit Document Template",
          path: "/:key/project/document/template/edit/:uid/:documentTemplateUid",
          initialParentRoute: "projectDocumentTemplates",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "documentTemplate",
              access: "edit",
            },
          }),
        },
        projectDocumentTemplateCreate: {
          title: "Create Document Template",
          path: "/:key/project/document/template/create/:uid",
          initialParentRoute: "projectDocumentTemplates",
          action: "projectDocumentTemplateEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "documentTemplate",
              access: "create",
            },
          }),
        },
        projectDocumentTemplateAnnotationEdit: {
          title: "Edit Annotation",
          path: "/:key/project/document/template/:documentTemplateAnnotationTypeKey/edit/:uid/:documentTemplateUid/:documentTemplateAnnotationUid",
          initialParentRoute: "projectDocumentTemplate",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "documentTemplate",
              access: "edit",
            },
          }),
        },
        projectDocumentTemplateAnnotationCreate: {
          title: "Create Annotation",
          path: "/:key/project/document/template/:documentTemplateAnnotationTypeKey/create/:uid/:documentTemplateUid/",
          initialParentRoute: "projectDocumentTemplate",
          action: "projectDocumentTemplateAnnotationEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "documentTemplate",
              access: "create",
            },
          }),
        },
        projectPipelineEdit: {
          title: "Edit Pipeline",
          path: "/:key/project/pipeline/edit/:uid/:pipelineUid",
          initialParentRoute: "projectPipelines",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipeline",
              access: "edit",
            },
          }),
        },
        projectPipelineCreate: {
          title: "Create Pipeline",
          path: "/:key/project/pipeline/create/:uid",
          initialParentRoute: "projectPipelines",
          action: "projectPipelineEdit",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipeline",
              access: "create",
            },
          }),
        },
        projectPipelineCopy: {
          title: "Copy Pipeline",
          path: "/:key/project/pipeline/copy/:uid/:pipelineUid",
          initialParentRoute: "projectPipelines",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipeline",
              access: "create",
            },
          }),
        },
        projectPipelineManageData: {
          title: "Manage Data",
          path: "/:key/project/pipeline/data/:uid/:pipelineUid",
          initialParentRoute: "projectPipelines",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipeline",
              access: "delete",
            },
          }),
        },

        pipelineBlockEditNavigator: {
          type: "tab",
          //header: ResourceHeader,
          tabNavigator: {
            position: "top",
            showIcon: false,
            showLabel: true,
            upperCaseLabel: true,
            scrollEnabled: true,
            itemRenderer: checkRoutePermissions,
          },
          initialRoute: "projectPipelineBlockEdit",
          initialParentRoute: "pipeline",
          routes: {
            projectPipelineBlockEdit: {
              label: "General",
              title: "Pipeline Block Settings",
              path: "/:key/project/pipeline/block/edit/:uid/:pipelineUid/:pipelineBlockUid",
              initialParentRoute: "projectPipeline",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "pipeline",
                  access: "edit",
                },
              }),
            },
            projectPipelineBlockIoEdit: {
              label: "Inputs / Outputs",
              title: "Pipeline Block Settings",
              path: "/:key/project/pipeline/block/edit/io/:uid/:pipelineUid/:pipelineBlockUid",
              initialParentRoute: "projectPipeline",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "pipeline",
                  access: "edit",
                },
              }),
            },
            projectPipelineBlockEventEdit: {
              label: "Run Triggers",
              title: "Pipeline Block Settings",
              path: "/:key/project/pipeline/block/edit/event/:uid/:pipelineUid/:pipelineBlockUid",
              initialParentRoute: "projectPipeline",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "pipeline",
                  access: "edit",
                },
              }),
            },
            projectPipelineBlockTemplateEdit: {
              label: "Template",
              title: "Pipeline Block Settings",
              path: "/:key/project/pipeline/block/edit/template/:uid/:pipelineUid/:pipelineBlockUid",
              initialParentRoute: "projectPipeline",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "appModule",
                  access: "edit",
                },
              }),
            },
            projectPipelineBlockAppModuleEdit: {
              label: "App Module",
              title: "Pipeline Block Settings",
              path: "/:key/project/pipeline/block/edit/module/:uid/:pipelineUid/:pipelineBlockUid",
              initialParentRoute: "projectPipeline",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "appModule",
                  access: "edit",
                },
              }),
            },
          },
        },

        pipelineBlockCreateNavigator: {
          type: "stack",
          //header: ResourceHeader,
          // tabNavigator: {
          //   position: "top",
          //   showIcon: false,
          //   showLabel: true,
          //   upperCaseLabel: true,
          //   scrollEnabled: true,
          //   itemRenderer: checkRoutePermissions,
          // },
          initialRoute: "projectPipelineBlockCreate",
          initialParentRoute: "pipeline",
          routes: {
            projectPipelineBlockCreate: {
              title: "Create Pipeline Block",
              path: "/:key/project/pipeline/block/create/:uid/:pipelineUid",
              initialParentRoute: "projectPipeline",
              action: "projectPipelineBlockEdit",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "pipelineBlock",
                  access: "create",
                },
              }),
            },
          },
        },
        // projectPipelineBlockEdit: {
        //   title: "Edit Pipeline Block",
        //   path:
        //     "/:key/project/pipeline/block/edit/:uid/:pipelineUid/:pipelineBlockUid",
        //   initialParentRoute: "projectPipeline",
        //   metadata: ({ route }) => ({
        //     permissions: {
        //       type: route.params.key,
        //       subtype: "pipelineBlock",
        //       access: "edit",
        //     },
        //   }),
        // },

        projectPipelineBlockAdd: {
          title: "Add Pipeline Block",
          path: "/:key/project/pipeline/block/add/:uid/:pipelineUid",
          initialParentRoute: "projectPipeline",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipelineBlock",
              access: "create",
            },
          }),
        },
        projectPipelineBlockMetrics: {
          title: "Pipeline Block Metrics",
          path: "/:key/project/pipeline/block/metrics/:uid/:pipelineUid/:pipelineBlockUid",
          initialParentRoute: "projectPipeline",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipelineBlock",
            },
          }),
        },
        projectPipelineBlockMonitor: {
          title: "Pipeline Block Monitor",
          path: "/:key/project/pipeline/block/monitor/:uid/:pipelineUid/:pipelineBlockUid",
          initialParentRoute: "projectPipeline",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipelineBlock",
            },
          }),
        },
        // projectPipelineBlockIoEdit: {
        //   title: "Manage Inputs / Outputs",
        //   path:
        //     "/:key/project/pipeline/block/io/:uid/:pipelineUid/:pipelineBlockUid",
        //   initialParentRoute: "projectPipeline",
        //   metadata: ({ route }) => ({
        //     permissions: {
        //       type: route.params.key,
        //       subtype: "pipeline",
        //       access: "edit",
        //     },
        //   }),
        // },
        // projectPipelineBlockEventEdit: {
        //   title: "Manage Run Triggers",
        //   path:
        //     "/:key/project/pipeline/block/event/:uid/:pipelineUid/:pipelineBlockUid",
        //   initialParentRoute: "projectPipeline",
        //   metadata: ({ route }) => ({
        //     permissions: {
        //       type: route.params.key,
        //       subtype: "pipelineBlock",
        //       access: "edit",
        //     },
        //   }),
        // },
        projectPipelineBlockLog: {
          title: "Pipeline Block Log",
          path: "/:key/project/pipeline/block/log/:uid/:pipelineUid/:pipelineBlockUid",
          initialParentRoute: "projectPipeline",
          metadata: ({ route }) => ({
            permissions: {
              type: route.params.key,
              subtype: "pipelineBlock",
            },
          }),
        },

        // devProjectAppModuleCreate: {
        //   title: "Create App Module",
        //   path: "/module/create",
        //   path: "/dev/project/module/create/:uid",
        //   initialParentRoute: "devProjectAppModules",
        //   action: "devProjectAppModuleEdit",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule",
        //       access: "create"
        //     }
        //   }
        // },
        // devProjectAppModuleEdit: {
        //   title: "Edit App Module",
        //   path: "/dev/project/module/edit/:uid/:appModuleUid",
        //   initialParentRoute: "devProjectAppModules",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule",
        //       access: "edit"
        //     }
        //   }
        // },
        // devProjectAppModuleLog: {
        //   title: "App Module Log",
        //   path: "/dev/project/module/log/:uid/:appModuleUid",
        //   initialParentRoute: "devProject",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule"
        //     }
        //   }
        // },
        // devProjectAppModuleServerLog: {
        //   title: "App Module Log",
        //   path: "/dev/project/module/log/:serverType/:uid/:appModuleUid",
        //   initialParentRoute: "devProject",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule"
        //     }
        //   }
        // },
        // devProjectAppModuleManagePackages: {
        //   title: "Manage Packages",
        //   path: "/dev/project/module/packages/:uid/:appModuleUid",
        //   initialParentRoute: "devProject",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule",
        //       access: "edit"
        //     }
        //   }
        // },
        // aiProjectAppModuleServerLog: {
        //   title: "App Module Log",
        //   path: "/ai/project/module/log/:serverType/:uid/:appModuleUid",
        //   initialParentRoute: "aiProject",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule"
        //     }
        //   }
        // },
        // aiProjectAppModuleLog: {
        //   title: "App Module Log",
        //   path: "/ai/project/module/log/:uid/:appModuleUid",
        //   initialParentRoute: "aiProject",
        //   metadata: {
        //     permissions: {
        //       type: "dev",
        //       subtype: "appModule"
        //     }
        //   }
        // },
        // aiProjectCreate: {
        //   title: "Create Ai Project",
        //   path: "/ai/project/create/",
        //   initialParentRoute: "aiProjects",
        //   action: "aiProjectEdit",
        //   metadata: {
        //     permissions: {
        //       type: "ai",
        //       subtype: "project",
        //       access: "create"
        //     }
        //   }
        // },
        // aiProjectEdit: {
        //   title: "Edit Ai Project",
        //   path: "/ai/project/edit/:uid",
        //   initialParentRoute: "aiProjects",
        //   metadata: {
        //     permissions: {
        //       type: "ai",
        //       subtype: "project",
        //       access: "edit"
        //     }
        //   }
        // },
        // aiProjectAppModuleCreate: {
        //   title: "Create App Module",
        //   path: "/module/create",
        //   path: "/ai/project/module/create/:uid",
        //   initialParentRoute: "aiProjectAppModules",
        //   action: "aiProjectAppModuleEdit",
        //   metadata: {
        //     permissions: {
        //       type: "ai",
        //       subtype: "appModule",
        //       access: "create"
        //     }
        //   }
        // },
        // aiProjectAppModuleEdit: {
        //   title: "Edit App Module",
        //   path: "/ai/project/module/edit/:uid/:appModuleUid",
        //   initialParentRoute: "aiProjectAppModules",
        //   metadata: {
        //     permissions: {
        //       type: "ai",
        //       subtype: "appModule",
        //       access: "edit"
        //     }
        //   }
        // }
        // projectAppModuleManagePackages: {
        //   title: "Manage App Module Packages",
        //   path: "/:key/project/module/libraries/:uid/:appModuleUid",
        //   initialParentRoute: ({ type }) => `${type.toLowerCase()}ProjectAppModules`,
        //   metadata: ({ type }) => ({
        //     permissions: {
        //       type: type,
        //       subtype: "appModule",
        //       access: "edit"
        //     }
        //   })
        // }
      },
    },
    projectDocumentTemplateNavigationContainer: {
      initialRoute: "projectDocumentTemplateTabNavigator",
      type: "container",
      // header: {
      //   // title: 'R14 Dev App',
      //   controlsLeft: HeaderControlsLeft,
      //   controlsRight: HeaderControlsRight,
      // },
      routes: {
        projectDocumentTemplateTabNavigator: {
          type: "tab",
          //header: ResourceHeader,
          tabNavigator: {
            position: "top",
            showIcon: false,
            showLabel: true,
            upperCaseLabel: true,
            scrollEnabled: true,
            itemRenderer: checkRoutePermissions,
            onWillBlur: (navigator, app) => {
              app.dm.resource.clearInstances();
            },
          },

          initialRoute: "projectDocumentTemplateDetails",
          routes: {
            projectDocumentTemplateDetails: {
              label: "Details",
              path: "/:key/project/document/template/details/:uid/:documentTemplateUid",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "documentTemplate",
                },
              }),
            },
            projectDocumentTemplateFields: {
              label: "Fields",
              path: "/:key/project/document/template/fields/:uid/:documentTemplateUid",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "documentTemplate",
                },
              }),
            },
            projectDocumentTemplateAnchors: {
              label: "Anchors",
              path: "/:key/project/document/template/anchors/:uid/:documentTemplateUid",
              metadata: ({ route }) => ({
                permissions: {
                  type: route.params.key,
                  subtype: "documentTemplate",
                },
              }),
            },
          },
        },
      },
    },
  },
});
